import { isTablet as isTabletBrowser, isMobile as isMobileBrowser, isIOS, isSafari } from "react-device-detect";
import React from 'react';

export const isMobile = () => {
    return isMobileBrowser;
}

export const isTablet = () => {
    return isTabletBrowser;
}

export const isDesktop = () => !isTablet() && !isMobile();

export function useIsMounted() {
    const ref = React.useRef(true)
    React.useEffect(() => {
        return () => {
            ref.current = false
        }
    }, [])
    return React.useCallback(() => ref.current, [])
}


export const canScanQR = () => {
    return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
}

export const isNotIOSSafari = () => {
    return isIOS && !isSafari;
}