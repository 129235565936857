import { APIS, deleteApi, getApi, putApi } from "../../utils/api"
import { ACTIONS } from "../../store/session";

export const getVenue = async (venueId) => {
  const venue = await getApi(APIS.VENUE_API, `venue/${venueId}`);
  return venue.Item;
}

export const updateVenueSettings = async (venueId, venueConfigurations, dispatch) => {
  await putApi(APIS.VENUE_API, `venue/settings`, {
    venueId: venueId,
    configurations: venueConfigurations,
  });

  dispatch({ type: ACTIONS.VENUE_SETTINGS_UPDATED, payload: venueConfigurations });
};

export const updateVenueServicePeriod = async (venueId, servicePeriod, dispatch) => {
  const servicePeriods = await putApi(APIS.VENUE_API, 'venue/service-period', { venueId, ...servicePeriod });
  dispatch({ type: ACTIONS.VENUE_SERVICE_PERIODS_UPDATED, payload: servicePeriods });
  return servicePeriods;
}

export const deleteVenueServicePeriod = async (venueId, servicePeriodId, dispatch) => {
  const servicePeriods = await deleteApi(APIS.VENUE_API, 'venue/service-period', { venueId, id: servicePeriodId });
  dispatch({ type: ACTIONS.VENUE_SERVICE_PERIODS_UPDATED, payload: servicePeriods });
  return servicePeriods;
}