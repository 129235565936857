import { APIS, getApi, putApi, postApi } from '../../utils/api';

export const STATUS = {
  IN_PROGRESS: 'in_progress',
  WAITING_FOR_PICKUP: 'waiting_for_pickup',
  COMPLETED: 'completed',
  ALL: 'all'
};

export const createOrder = async (params) => {
  const res = await postApi(APIS.ORDER_API, 'order', params);
  return res;
};

export const getOrders = async (venueId, status, starting = undefined, from, to) => {
  const params = { venueId, from, to };
  if (status !== STATUS.ALL) params.status = status;
  if (starting) params.starting = starting;
  const orders = await getApi(APIS.ORDER_API, `orders`, { queryStringParameters: params });
  return orders;
};

export const getSearchOrders = async (venueId, search, from, to, status = STATUS.ALL) => {
  const params = { venueId, search, from, to };
  if (status !== STATUS.ALL) params.status = status;
  const orders = await getApi(APIS.ORDER_API, `orders/search`, { queryStringParameters: params });
  return orders;
}

export const updateOrderStatus = async (venueId, orderId, status) => {
  let params = {
    "venueId": venueId,
    "orderId": orderId,
    "status": status
  };
  return await putApi(APIS.ORDER_API, `order/status`, params);

};

export const buzzCustomer = async (venueId, orderId) => {
  let params = {
    "venueId": venueId,
    "orderId": orderId
  }
  return await postApi(APIS.ORDER_API, `order/buzz`, params);
};

export const notifyViaSMS = async (venueId, orderId) => {
  let params = {
    "venueId": venueId,
    "orderId": orderId
  }
  return await postApi(APIS.ORDER_API, `order/sms`, params);
};

export const getVenueGroups = async (venueId) => {
  const venue = await getApi(APIS.VENUE_API, `venue/${venueId}/groups`);
  return venue;
}

export const getVenueTables = async (venueId) => {
  const venue = await getApi(APIS.VENUE_API, `venue/${venueId}/tables`);
  return venue;
}

export const getSuggestGroup = async (venueId) => {
  const params = { venueId };
  return await postApi(APIS.VENUE_API, `venue/suggest/group`, params);
}

export const getCustomerByCode = async (code) => {
  const params = { code };
  const customer = await getApi(APIS.ORDER_API, `order/customer/code`, { queryStringParameters: params });
  return customer;
}

export const getCustomerByPhoneNumber = async (telephone) => {
  const params = { telephone };
  const customer = await getApi(APIS.ORDER_API, `order/customer/telephone`, { queryStringParameters: params });
  return customer;
}

export const getSuggestedOrderNo = async (venueId) => {
  const orderNo = await getApi(APIS.VENUE_API, `venue/${venueId}/order-no`);
  return `${orderNo}`;
}