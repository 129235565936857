import { Button, Col, Input, Row } from 'antd';
import React from 'react';
import { truncateOrderNo } from '../../../utils/commons';

const OrderNo = ({ formStep, steps, orderNo, onChange, onAdd, onCancel, onEdit, isEditing, shouldOrderNoTruncate, isDisbaled = false }) => {

    return (
        formStep === steps.ORDER_NO ?
            (
                <Col className='kyu-npt-cntnr'>
                    <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Order no</p>
                    <Row className='kyu-rw-npt kymt_2'>
                        <Col className={`ky_order_no kymb_2 ${isEditing ? 'ky_order_no_edit_width' : 'ky_order_no_add_width'}`}>
                            <Input
                                placeholder='Enter an order number'
                                value={orderNo}
                                onChange={onChange}
                                disabled={isDisbaled}
                            />
                            <Button disabled={isDisbaled} type='primary' className={`ky_btn ky_btn_rng ${isEditing ? 'edit-btn' : ''}`} onClick={onAdd}>{isEditing ? 'Save' : 'Add'}</Button>
                            {isEditing && <Button type='primary' className='ky_btn ky_btn_blck' onClick={onCancel}>Cancel</Button>}
                            {isEditing && <div className="ky_ordr_no_hnt">Only the last 4 digits of the order number will be displayed in the order.</div>}
                        </Col>
                    </Row>
                </Col>
            )
            :
            (
                <Col className='kyu-npt-dtls'>
                    <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Order no</p>
                    <p className='kyu-fnt-mdm'>{truncateOrderNo(orderNo, shouldOrderNoTruncate)}</p>
                    {formStep > steps.ORDER_NO ? <div><Button className='ky_link kyu-fnt-mdm' type='link' onClick={onEdit}>Edit</Button></div> : <p></p>}
                </Col>
            )
    )
}

export default OrderNo;