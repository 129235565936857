import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AWS_CONFIG } from './config/app-config';
import { awsAmplifyApiInit } from './utils/aws-configure';
import Amplify from 'aws-amplify';
import { SessionStateProvider } from './store/session';
import { OrderStateProvider } from './store/orders';

Amplify.configure(AWS_CONFIG);
awsAmplifyApiInit();

const app = (
  <SessionStateProvider>
    <OrderStateProvider>
      <App />
    </OrderStateProvider>
  </SessionStateProvider>
);

ReactDOM.render(
  app,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
