import { useContext, useRef } from 'react';
import {Card, Button} from 'antd';
import { notifiableAPICall } from '../../components/notification';
import { createOrder } from '../actions';
import { sessionStore } from '../../../store/session';
import { OPTION_LEVELS } from './constants';
import QrScanner from './qrScanner';
import NFCAndCusId from './nfcCusId';
import TeleNoBeeper from './teleNoBeeper';

const ConnectUserView = (props) => {
    const {
        orderNo,
        tableNo,
        groupName,
        tableId,
        groupId,
        drawerClose,
        clearOrder,
        onOrderAdd,
        customer,
        setCustomer,
        optionLevel,
        setOptionLevel,
        initialOptionLevel,
        isError,
        setError,
        isOrderReady,
        setOrderReady,
        orderReadyDisable,
        setOrderReadyDisable,
        phoneNumber,
        setPhoneNumber,
        withNoCustomer,
        setWithNoCustomer,
        beeperValue,
        setBeeperValue,
        isRetry,
        setIsRetry,
        selectedConnectCustomerOption,
        setSelectedConnectCustomerOption
    } = props;

    const { state } = useContext(sessionStore);
    const { venueId } = state.toJS();

    const childRef = useRef();

    const _setCustomer = (data) => {
        try {
            let res = JSON.parse(data);
            if (!res.name || !res.id) throw new Error("Invalid QR code");
            setCustomer({ name: res.name, id: res.id });
        } catch (e) {
            throw new Error("Invalid QR code");
        }
    }

    const createNewOrder = async () => {
        try {
            setOrderReadyDisable(true);
            let params = {
                venueId: venueId,
                orderNo: orderNo,
                tableNo: tableNo ? tableNo : undefined,
                tableId: tableNo ? tableId : undefined,
                groupName: groupName ? groupName : undefined,
                groupId: groupName ? groupId : undefined
            }

            if (withNoCustomer && phoneNumber) {
                params.phoneNumber = phoneNumber;
            } else if (withNoCustomer && beeperValue) {
                params.beeperNo = beeperValue;
            } else {
                params.customerId = customer.id;
            }

            const result = await notifiableAPICall(async () => createOrder(params),
                "Creating Your Order.",
                "Please wait.",
                "Order Created!",
                "Something went wrong please try again!",
                true
            );
            if (result.data) {
                clear();
                onOrderAdd(result.data);
            }
            drawerClose();
        } catch (e) {
            console.log(e);
            setOrderReadyDisable(false);
        }
    }

    const nextOption = () => {
        setError(false);
        setIsRetry(false);
        setOptionLevel(optionLevel + 1);
    }

    const clear = () => {
        setOptionLevel(initialOptionLevel);
        setIsRetry(false);
        setCustomer();
        setError();
        setOrderReady(false);
        clearOrder();
    }

    const retry = () => {
        setCustomer();
        setOrderReady(false);
        setIsRetry(true);
        if (childRef.current) childRef.current.reset();
    }

    const renderBody = () => {
        switch (optionLevel) {
            case OPTION_LEVELS.QR_SCANNER:
                return opQRScanner();

            case OPTION_LEVELS.NFC_CUS_ID:
                return opNFCandCusId();

            case OPTION_LEVELS.Tel_NO_BEEPER:
                return opTeleNoBeeper();

            default:
                break;
        }
    }

    const opQRScanner = () => {
        return (
            <QrScanner
                setCustomer={_setCustomer}
                nextOption={nextOption}
                isError={isError}
                setError={setError}
                setOrderReady={setOrderReady}
                customer={customer}
            />
        );
    }

    const opNFCandCusId = () => {
        return (
            <NFCAndCusId
                setCustomer={setCustomer}
                customer={customer}
                setError={setError}
                isError={isError}
                setOrderReady={setOrderReady}
                nextOption={nextOption}
                ref={childRef}
                radioValue={selectedConnectCustomerOption}
                setRadioValue={setSelectedConnectCustomerOption}
            />
        );
    }

    const opTeleNoBeeper = () => {
        return (
            <TeleNoBeeper
                setCustomer={setCustomer}
                customer={customer}
                setError={setError}
                isError={isError}
                setOrderReady={setOrderReady}
                nextOption={nextOption}
                ref={childRef}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                withNoCustomer={withNoCustomer}
                setWithNoCustomer={setWithNoCustomer}
                beeperValue={beeperValue}
                setBeeperValue={setBeeperValue}
                setIsRetry={setIsRetry}
                isRetry={isRetry}
                radioValue={selectedConnectCustomerOption}
                setRadioValue={setSelectedConnectCustomerOption}
            />
        );
    }

    const retryView = beeperValue === undefined ?
        <Button className='ky_btn kyu-fnt-mdm ky_btn_lng ky_btn_blck kymr_2 kymt_2' disabled={orderReadyDisable} onClick={retry}>Retry</Button>
        : <div></div>;

    const orderReadyView = isOrderReady || !!customer ?
        <div className='ky_add_retry_cntnr ky-w-100' >
            <div className='ky_lft ky-w-100'>
                {retryView}
                <Button className='ky_btn ky_btn_rng kyu-fnt-mdm ky_btn_lng kymt_2' disabled={orderReadyDisable} onClick={createNewOrder}>Add</Button>
            </div>
        </div>
        :
        null

    return (
        <Card className='ky_crd_opt'>
            <div className='ky_crd_opt_bdy'>
                {
                    renderBody()
                }
                {
                    orderReadyView
                }
            </div>
        </Card>
    );
}

export default ConnectUserView;
