export const AWS_CONFIG = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_uMoMSvpJ3',
        userPoolWebClientId: '6maalcubhe2m474c9dvl7cm950',
    }
};

export const CONFIG = {
    baseApiUrl: 'https://api.dev.kyuu.app/dev',
    apiStage: 'dev',
    stateUndoPeriod: 60000
};

export const DEFAULT_COUNTRY_CODE = 'LK';

export const AVAILABLE_COUNTRY_CODES = ['LK', 'AU']