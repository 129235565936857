import React, { useContext, useState, useEffect } from 'react';
import { Layout, Popover, Button, Card, Row, Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { Redirect, Route, Switch, withRouter, useHistory } from "react-router-dom";
import Orders from "../orders/orders";
import Venue from "../venue/venue";
import { isDesktop } from "../../utils/view";
import { sessionStore } from '../../store/session';
import { logout } from '../../actions/auth';
import SiderBody from './siderBody';

const { Header, Sider, Content } = Layout;

export const ROUTES = {
    ORDERS: "orders",
    ORDERS_ALL: "orders/all",
    ORDERS_IN_PROGRESS: "orders/in_progress",
    ORDERS_WAITING_FOR_PICK_UP: "orders/waiting_for_pickup",
    ORDERS_COMPLETED: "orders/completed",
    VENUE: "venue",
    USERS: "users"
}

const COLLAPSED_WIDTH = 1200;

const Main = () => {
    const [collapsed, setCollapsed] = useState(!isDesktop() || window.innerWidth < COLLAPSED_WIDTH);
    const { state } = useContext(sessionStore);
    const { firstName, lastName, email, isVenueAdmin } = state.toJS();

    useEffect(() => {
        function handler () {
            if(window.innerWidth < COLLAPSED_WIDTH) setCollapsed(true);
        }
        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, []);

    const history = useHistory();

    const routeUrl = (route) => `/${route}`;

    const renderView = (route) => {
        const { page, state } = route.match.params;
        switch (page) {
            case ROUTES.ORDERS:
                return <Orders state={state} />;
            case ROUTES.VENUE:
                if (isVenueAdmin)
                    return <Venue />;
                return <Redirect to={routeUrl(ROUTES.ORDERS_ALL)} />
            default:
                return <Redirect to={routeUrl(ROUTES.ORDERS_ALL)} />
        };
    }

    const handleLogout = async () => {
        await logout();
        window.location.reload();
    }

    const routeTo = (route) => {
        history.push({ pathname: routeUrl(route), search: history.location.search });
    }

    const onCollapse = (collapsed) => {
        setCollapsed(collapsed);
    }


    const sidePanel = (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} className="side-panel">
            <div className="ky_logo ky_logo_sdr" ></div>
            <div className="ky_main_menu">
                <div className="ky_sdr_header_sync" ></div>
                <SiderBody routeTo={routeTo} isVenueAdmin={isVenueAdmin} collapsed={collapsed} />
            </div>
        </Sider>
    );

    const userDetails = (
        <Card className="ky_prof_info">
            <div>
                <div>
                    <UserOutlined />
                </div>
                <div>
                    <h4>
                        <strong>{`${firstName} ${lastName}`}</strong>
                    </h4>
                    <p> {`${email}`}</p>
                </div>
                <div>
                    <Button className="ky_mn_btn ky_btn ky_btn_lng ky_btn_rng ky_btn_lgn kymb_0" htmlType="submit" onClick={handleLogout}>Logout</Button>
                </div>
            </div>
        </Card>
    );

    return (
        <Layout className="Kyu-layout">
            {sidePanel}
            <Layout>
                <Header className="ky_hdr">
                    <img src='/kyuu-logo.svg' alt='Kyuu' className="ky_logo ky_logo_img_header" />
                    <div className="Kyu-logged-user">
                        <Popover
                            placement="bottomLeft"
                            content={userDetails}
                            trigger="click"
                        >
                            <Button type="link" className="ky_btn_usr">
                                <Row>
                                    <Avatar className="ky_vtr" icon={<UserOutlined />} />
                                </Row>
                            </Button>
                        </Popover>
                    </div>
                </Header>
                <Content className="Kyu-content">
                    <Switch>
                        <Route path={"/:page/:state"} render={renderView} />
                        <Route path={"/:page"} render={renderView} />
                        <Redirect from="/" to="/orders/all" />
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    );
}

export default withRouter(Main);