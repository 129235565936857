import React from 'react';

export const ICONS = {
    ALL: "all.svg",
    IN_PROGRESS: "in-progress.svg",
    WAITING_FOR_PICKUP: "waiting-for-pick.svg",
    COMPLETED: "completed.svg",
    SETTINGS: "settings.svg",
    ORDER_STATS: "order-stat.svg",
    BUZZER: "buzzer.svg"
};

export const ImageIcon = ({ icon }) => {
    return <img className="ky_icon" alt={icon} src={`../icons/${icon}`} />
}