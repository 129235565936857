import Amplify from "aws-amplify";
import { CONFIG } from "../config/app-config";
import { APIS } from "./api";

export function awsAmplifyApiInit() {
    const { baseApiUrl } = CONFIG;

    Amplify.configure({
        API: {
            endpoints: [
                {
                    name: APIS.USER_API,
                    endpoint: `${baseApiUrl}-user-api/`
                },
                {
                    name: APIS.VENUE_API,
                    endpoint: `${baseApiUrl}-venue-api/`
                },
                {
                    name: APIS.ORDER_API,
                    endpoint: `${baseApiUrl}-order-api/`
                }
            ]
        }
    });
}
