import { AutoComplete, Button, Col, Divider, Input, Row, Switch } from 'antd';
import React from 'react';

const TableNoField = ({ tableNoEnable, formStep, steps, existingTable,
    onChangeExistingTable, existingTableNos, onTableSelect,
    onTableChange, onAdd, onSkip, tableNo, onEdit, isEditing }) => {

    if (!tableNoEnable) return null;

    const existingTableNoBtnView = formStep === steps.TABLE_NO ?
        <Row className='kyu-rw-swtch'>
            <Col>
                <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Add to existing table </p>
            </Col>
            <Switch size='small' checked={existingTable} onChange={onChangeExistingTable} />
        </Row>
        :
        null;

    const existingTableNoView = existingTable ?
        <AutoComplete
            style={{ width: 200 }}
            placeholder='Select a table number'
            value={tableNo}
            options={existingTableNos}
            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            onSelect={onTableSelect}
            onChange={onTableChange}
            defaultOpen={true}
        />
        :
        <Input
            placeholder='Enter a table number'
            value={tableNo}
            onChange={(value) => onTableChange(value.target.value)}
        />

    const tableNoView = formStep === steps.TABLE_NO ?
        (
            <Col className='kyu-npt-cntnr'>
                <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Table no</p>
                <Row className='kyu-rw-npt kymt_2'>
                    <Col className="ky_tbl_no kymb_2">
                        {
                            existingTableNoView
                        }
                        <Button type='primary' className='ky_btn ky_btn_rng kyu-fnt-mdm add-table' onClick={onAdd}>{isEditing ? "Save" : "Add"}</Button>
                        <Button type='primary' className='ky_btn ky_btn_blck' onClick={() => onSkip(isEditing)}>{isEditing ? "Cancel" : "Skip"}</Button>
                    </Col>
                </Row>
            </Col>
        )
        :
        (
            <Col className='kyu-npt-dtls'>
                <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Table no</p>
                <p className='kyu-fnt-mdm'>{tableNo}</p>
                {formStep > steps.TABLE_NO ? <div><Button className='ky_link kyu-fnt-mdm' type='link' onClick={onEdit}>Edit</Button></div> : <p></p>}
            </Col>
        );

    return (
        <>
            {existingTableNoBtnView}
            <Row className='kyu-rw'>
                {tableNoView}
            </Row>
            <Divider />
        </>
    )
}

export default TableNoField;