import { useEffect, useState } from "react";
import { Button, Form, Input, Drawer, Spin } from "antd";
import { LoadingIcon } from "../components/loading";
import { sendForogotPasswordCode, resetNewPassword } from "../../actions/auth";

const ForgotPasswordDrawer = ({
  isForgotPassword,
  isMailEntered,
  setIsMailEntered,
  setIsForgotPassword,
}) => {
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState(null);
  const [codeDeliveryMsg, setCodeDeliveryMsg] = useState("");

  //eslint-disable-next-line
  const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

  const sendCode = async (userParams) => {
    setLoading(true);
    setErrorMessage(null);
    setUserName(userParams.userName);
    await sendForogotPasswordCode(userParams.userName)
    .then(result => {
      if(result.CodeDeliveryDetails){
        setCodeDeliveryDetails(result.CodeDeliveryDetails);
      }
    })
    .catch(error => {
      if (error && error.message === "Username/client id combination not found.") {
        setErrorMessage("You are not a registered user");
        setLoading(false);
        return;
      }
      if (error) {
        setErrorMessage("Error occured");
        setLoading(false);
        return;
      }
    })
    setLoading(false);
    setIsMailEntered(true);
  };

  const resetPassword = async (credentialParams) => {
    setLoading(true);
    setErrorMessage(null);
    if (credentialParams.password !== credentialParams.confirmPassword) {
      setErrorMessage("The passwords you entered do not match, Please try again.");
      setLoading(false);
      return;
    }
    else if (!passwordRegex.test(credentialParams.password)) {
      setErrorMessage(
        "Password must contain at least 8 characters that include at least 1 lowercase character,1 uppercase character,1 number,and 1 special character in (!@#$%^&*)"
      );
      setLoading(false);
      return;
    }

    const error = await resetNewPassword(
      userName,
      credentialParams.code,
      credentialParams.password
    );
    if(error){
      if(error === "Invalid verification code provided, please try again."){
        setErrorMessage("Invalid Verification Code,Please Try Again");
        setLoading(false);
        return;
      }
      else {
        setErrorMessage("Sorry, Something went wrong please try again");
        setLoading(false);
        return;
      } 
    }
    setLoading(false);
    setIsForgotPassword(false);
    setIsMailEntered(false);
    setErrorMessage(null);
  };

  const triggerStates = () => {
    setIsForgotPassword(false);
    setIsMailEntered(false);
    setErrorMessage(null);
  };

  const errorView = errorMessage ? (
    <div className="ky_errmsg kymt_3">{errorMessage}</div>
  ) : null;

  useEffect(()=>{
    if(!!codeDeliveryDetails && codeDeliveryDetails.DeliveryMedium === 'EMAIL'){
      setCodeDeliveryMsg(`We have e-mailed your password reset code, Please check your email ${codeDeliveryDetails.Destination}`); 
    }
    else if(!!codeDeliveryDetails && codeDeliveryDetails.DeliveryMedium === 'SMS') {
      setCodeDeliveryMsg(`We'll send a message with a verification code to ${codeDeliveryDetails.Destination}`);
    }
  },[codeDeliveryDetails])

  const codeSendView = codeDeliveryDetails ? (
    <div className="ky_cd_msg kymt_3">{codeDeliveryMsg}</div>
  ) : null;


  return (
    <Drawer
      placement="left"
      height="auto"
      width="auto"
      className="ky_btm_drwr ky_btm_drwr_sml"
      closable={true}
      onClose={triggerStates}
      visible={isForgotPassword}
    >
      {!!codeDeliveryDetails && isMailEntered ? (
        <Form layout="vertical" onFinish={resetPassword}>
          {errorView}
          <Form.Item
            label="Verification code"
            name="code"
            rules={[
              {
                required: true,
                message: "Please input your verification code",
              },
            ]}
          >
            <Input
              className="kypl_1 ky_int ky_int_lgnfrm"
              placeholder="Please input your verification code"
              disabled={loading ? true : false}
            ></Input>
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your new password",
              },
            ]}
          >
            <Input.Password
              placeholder="Type you new password here"
              disabled={loading ? true : false}
            />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please re-enter your new password",
              },
            ]}
          >
            <Input.Password
              placeholder="Type you new password here too"
              disabled={loading ? true : false}
            />
          </Form.Item>
          <Form.Item className="kymb_1">
            <Button
              type="primary"
              htmlType="submit"
              block
              shape="rounded"
              className="ky_btn ky_btn_rng ky_btn_wd ky_btn_lgn kymb_0"
            >
              Save
            </Button>
          </Form.Item>
          {codeSendView}
        </Form>
      ) : (
        <div>
          <div className="ky_fgtpwd_ttle">Forgot Password?</div>
          <div className="ky_fgtpwd_txt kymb_3">
            We will send you a verification code to your email.
          </div>
          <Form layout="vertical" onFinish={sendCode}>
            {errorView}
            <Form.Item
              label="User Name"
              name="userName"
              rules={[
                {
                  required: true,
                  message: "Please input your user name here",
                },
              ]}
            >
              <Input
                className="kypl_1 ky_int ky_int_lgnfrm"
                placeholder="Please input your user name here"
                disabled={loading ? true : false}
              ></Input>
            </Form.Item>
            <Form.Item className="kymb_1">
              <Button
                type="primary"
                htmlType="submit"
                block
                shape="rounded"
                className="ky_btn ky_btn_rng ky_btn_wd ky_btn_lgn kymb_0"
                icon={
                  loading ? (
                    <Spin
                      className="ky_spin"
                      indicator={LoadingIcon(14, "white")}
                    />
                  ) : null
                }
              >
                Request Password Reset
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </Drawer>
  );
};
export default ForgotPasswordDrawer;
