import { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Divider } from 'antd';
import { notifyError } from '../../components/notification';
import QrReader from 'react-qr-reader';
import { isMobile, isTablet } from '../../../utils/view';
import { MODAL_WIDTH } from './constants';
import StatusIcon, { TYPE } from '../../components/statusIcon';

const QrScanner = (Props) => {

    const {
        setCustomer,
        nextOption,
        isError,
        setError,
        setOrderReady,
        customer
    } = Props;

    const [isQRScannerVisible, setQRScannerVisible] = useState(false);
    const [modalWidth, setModalWidth] = useState(MODAL_WIDTH.OTHER);

    const showQRScanner = () => {
        setQRScannerVisible(true);
    }

    const onCloseQRModal = () => {
        setQRScannerVisible(false);
    }

    const handleQRScan = data => {
        if (!data) return;
        try {
            setQRScannerVisible(false);
            setCustomer(data);
            setOrderReady(true);
        } catch (e) {
            handleQRError(e);
        }
    }

    const handleQRError = err => {
        console.error(err);
        setQRScannerVisible(false);
        notifyError(err.message);
        setError(true);

    }

    useEffect(() => {
        if (isTablet()) {
            setModalWidth(MODAL_WIDTH.OTHER);
        }
        else if (isMobile()) {
            setModalWidth(MODAL_WIDTH.OTHER);
        }
        else {
            setModalWidth(MODAL_WIDTH.DESKTOP);
        }
    }, []);

    const qrErrorView = isError ?
        <div className="ky-msg-wrapper">
            <StatusIcon type={TYPE.FAILED} />
            <p className='ky-err-txt'>That didn't work, scan again or click for more connection options</p>
        </div>
        :
        null;

    const qrBtnView = !!customer ?
        <div className="ky-msg-wrapper">
            <StatusIcon type={TYPE.SUCCESS} />
            <p className='ky-success-txt'>Customer <strong>{customer.name}</strong> was found.</p>
        </div>
        :
        (
            <div className='ky_lft'>
                <Button type='primary' className='ky_btn ky_btn_rng kyu-fnt-mdm kymr_2 kymt_2' onClick={showQRScanner}>Scan QR</Button>
                <Button type='primary' className='ky_btn ky_btn_blck kyu-fnt-mdm kymt_2' onClick={nextOption}>Select another option</Button>
            </div>
        );

    const qrScannerView = isQRScannerVisible ?
        (
            <QrReader
                delay={300}
                onError={handleQRError}
                onScan={handleQRScan}
                style={{ width: '100%' }}
            />
        )
        :
        null;

    const qrScan = (<Modal
        visible={isQRScannerVisible}
        title='Scan QR Code'
        footer={null}
        onCancel={onCloseQRModal}
        centered
        width={modalWidth}
        maskClosable={false}
    >
        {
            qrScannerView
        }
    </Modal>);

    return (
        <Row>
            <Col>
                <p className='kyu-txt-lgn kyu-fnt-mdm'>Scan the QR code in the customer's app now</p>
                <Divider />
                {
                    qrErrorView
                }
                {
                    qrBtnView
                }
                {
                    qrScan
                }
            </Col>
        </Row>
    );
}

export default QrScanner;
