import { useState } from 'react';
import { Button } from 'antd';
import NewOrderDrawer from './drawer';

const AddOrder = ({ onOrderAdd }) => {
    const [visible, setVisible] = useState(false);

    const toggleDrawer = () => {
        setVisible(!visible);
    }

    const onClose = () => {
        setVisible(false);
    }

    const addOrderDrawer = visible ? <NewOrderDrawer visible={visible} onClose={onClose} onOrderAdd={onOrderAdd} /> : null;

    return (
        <>
            <div className='ky_crd_addordr_bdy'>
                <Button className="ky_btn ky_btn_lrg ky_btn_rng" onClick={toggleDrawer}>Add New Order</Button>
            </div>
            {addOrderDrawer}
        </>
    );
}

export default AddOrder;
