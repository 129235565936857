import moment from "moment";

export const LONG_DATE_TIME_FORMAT = "yyyy-MMM-DD hh:mm a";
export const SHORT_DATE_TIME_FORMAT = "MMM-DD hh:mm a";
export const TIME_FORMAT_a = "hh:mm a";
export const TIME_FORMAT_A = "hh:mm A";
export const TIME_FORMAT_24 = "HH:mm:ss";
const DATE_FORMAT = "yyyy-MM-DD";
export const DISPLAY_DATE_FORMAT = "dddd D MMMM";

const toTime = (refDate, str) => {
    const time = str.trim().split(':').map(t => parseInt(t, 10));
    const now = new Date(refDate);
    now.setHours(time[0], time[1], time[2]);
    return now;
};

export const generateOrderServicePeriod = (refDate, def) => {
    let from = toTime(refDate, def.from);
    let fromMillis = from.getTime();
    let to;
    let toMillis = 0;
    let isDayChange = false;
    if (def.type === "exact") {
        to = toTime(refDate, def.to);
        toMillis = to.getTime();
        if (toMillis < fromMillis) {
            // next day
            toMillis += 86400000;
            to = new Date(toMillis);
            isDayChange = true;
        }
    }
    else if (def.type === "relative") {
        /*     {
        name: "Shift",
        from: "06:00:00",
        offset: -36,
        type: "relative"
    }, */
        toMillis = fromMillis + Math.round(def.offset * 3600000);
        to = new Date(toMillis);
        if (fromMillis > toMillis) {
            const temp = from;
            from = to;
            to = temp;
        }
    }
    return {
        from: from,
        to: to,
        isDayChange
    }
};

export const generateOrderDatePeriod = (range) => {
    const start = moment(range[0]);
    let end = moment(range[1]);
    end = end.endOf('day');
    
    return {
        from: start,
        to: end,
        isDayChange: !start.isSame(end, 'date')
    }
}

export const toLongDateTimeString = (date) => moment(date).format(LONG_DATE_TIME_FORMAT);

export const toShortDateTimeString = (isoDate) => moment(isoDate).format(SHORT_DATE_TIME_FORMAT);

export const diffInMillis = (date1, date2) => moment(date1).diff(moment(date2), 'millisecond');

export const to24to12aTimeString = (time) => moment(time, TIME_FORMAT_24).format(TIME_FORMAT_a);

export const toDateString = (date) => moment(date).format(DATE_FORMAT);

export const toTime12aString = (date) => moment(date).format(TIME_FORMAT_a);

export const toTime24String = (date) => moment(date).format(TIME_FORMAT_24);

export const to12Ato24TimeString= (time) => moment(time, TIME_FORMAT_A).format(TIME_FORMAT_24);

export const toMomentTimeA = (time) => moment(time, TIME_FORMAT_A);

export const toMomentTime24 = (time) => moment(time, TIME_FORMAT_24);