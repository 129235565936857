import { API, Auth } from 'aws-amplify';

export const APIS = {
    ORDER_API: "orderAPI",
    USER_API: "userAPI",
    VENUE_API: "venueAPI"
}

export async function getApi(api, path, params) {
    let options = (params) ? params : {};
    await Auth.currentSession().then((session) => {
        let jwtToken = session.idToken.jwtToken;
        options = {
            ...options,
            headers: {
                Authorization: jwtToken
            }
        }
    });
    return API.get(api, path, options);
}

export async function putApi(api, path, body) {
    let options = { body };
    await Auth.currentSession().then((session) => {
        let jwtToken = session.idToken.jwtToken;
        options = {
            ...options,
            headers: {
                Authorization: jwtToken
            }
        }
    });
    return API.put(api, path, options);
}

export async function postApi(api, path, body) {
    let options = { body };
    await Auth.currentSession().then((session) => {
        let jwtToken = session.idToken.jwtToken;
        options = {
            ...options,
            headers: {
                Authorization: jwtToken
            }
        }
    });
    return API.post(api, path, options);
}

export async function deleteApi(api, path, body) {
    let options = { body };
    await Auth.currentSession().then((session) => {
        let jwtToken = session.idToken.jwtToken;
        options = {
            ...options,
            headers: {
                Authorization: jwtToken
            }
        }
    });
    return API.del(api, path, options);
}