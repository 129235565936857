import { Auth } from "aws-amplify";
import { ACTIONS } from "../store/session";
import { APIS, getApi } from '../utils/api';

export const isSessionAuthenticated = async (dispatch) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        postLogin(user, dispatch, ACTIONS.LOGGED);
        return true;
    } catch (e) {
        console.log(e);
    }
    return false;
}

export const signIn = async (username, password, newPassword, dispatch) => {
    try {
        let user = await Auth.signIn(username.trim(), password.trim());
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            if (!newPassword) {
                return ('NEW_PASSWORD_REQUIRED');
            }
            user = await Auth.completeNewPassword(user, newPassword.trim());
            postLogin(user, dispatch, ACTIONS.LOGGED);
        } else {
            postLogin(user, dispatch, ACTIONS.LOGGED);
        }
    } catch (err) {
        console.log('ERR:', err);
        return err.message ? err.message : "Error occured";
    }
}

export const sendForogotPasswordCode = async (userName) => {
    const res = await Auth.forgotPassword(userName.trim());
    return res;
};

export const resetNewPassword = async (userName, code, password) => {
    try {
        await Auth.forgotPasswordSubmit(userName.trim(), code.trim(), password.trim());
    } catch (err) {
        return err.message ? err.message : "Error occured";
    }
};

const getVenue = async (venueId) => {
    const venue = await getApi(APIS.VENUE_API, `venue/${venueId}`);
    return venue.Item;
}

const postLogin = async (user, dispatch, dispatchType) => {
    const payload = user.getSignInUserSession().getIdToken().payload;
    dispatch({ type: dispatchType, payload });
    const venue = await getVenue(payload["custom:venueId"]);
    dispatch({ type: ACTIONS.VENUE_LOADED, payload: venue });

}

export const logout = async () => {
    try {
        await Auth.signOut();
    }
    catch (err) {
        console.log('ERR:', err);
    }
}
