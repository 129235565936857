import { useState } from 'react';
import { Space, Button, Modal, Card } from 'antd';
import { PhoneOutlined, MessageOutlined, BellOutlined } from '@ant-design/icons';
import { buzzCustomer, notifyViaSMS } from '../actions';
import { notifiableAPICall, notifyError } from '../../components/notification';

const NOTIFICATION_TYPES = {
  CALL: "call",
  BUZZ: "buzz",
  SMS: "sms"
}

export const NotifyButtons = ({ order, venueId, close, onBuzz }) => {

  const [phoneModalVisible, setPhoneModalVisible] = useState(false);

  const buzzNow = async () => {
    const buzzCount = await buzzCustomer(venueId, order.orderId);
    onBuzz(order.orderId, buzzCount.buzzCount);
  };

  const handleOnClick = async (type) => {
    if (type === NOTIFICATION_TYPES.CALL) {
      // window.open(`tel:${order.customerTelephone}`);
      setPhoneModalVisible(true);

    } else if (type === NOTIFICATION_TYPES.BUZZ) {
      try {
        await notifiableAPICall(buzzNow,
          "buzz-customer",
          "Please wait",
          "Customer has been notified successfully",
          undefined, true);
      } catch (e) {
        if(e?.response?.data?.error?.errorCode === "ORDER_ALREADY_COMPLETED") return notifyError("Customer has completed this order.")
        notifyError("Something went wrong in notifying the customer. Please Retry.")
      }
    } else if (type === NOTIFICATION_TYPES.SMS) {
      try {
        await notifiableAPICall(async () => await notifyViaSMS(venueId, order.orderId),
          "notitfy-customer-with-sms",
          "Please wait",
          "Message has been sent successfully", undefined, true
        );
      } catch (e) {
        if(e?.response?.data?.error?.errorCode === "ORDER_ALREADY_COMPLETED") return notifyError("Customer has completed this order.")
        notifyError("Something went wrong when sending the message. Please Retry.")
      }
    }

    if (close) close();
  };

  const phoneNumberModal =
    <Modal
      className="ky_modal_nv"
      visible={phoneModalVisible}
      onCancel={() => setPhoneModalVisible(false)}
      footer={<Button
        size='large'
        className='ky_btn ky_btn_bl ky_ntf_btn'
        onClick={() => setPhoneModalVisible(false)}
      >Ok</Button>
      }
    >
      <Card
        title={"Phone Number"}
        headStyle={{ textAlign: 'center' }}
        bordered={false}
        className='ky_crd_tbl_ordrs'
      >
        <h1 className="kyu_phn_mdl_fld">{order.customerTelephone}</h1>
      </Card>
    </Modal>

  return (
    <Space className='ky_ntf_spc'>
      <div>
        <Button
          size='large'
          className='ky_btn ky_btn_rd ky_ntf_btn'
          onClick={() => handleOnClick(NOTIFICATION_TYPES.CALL)}
          disabled={!order.customerTelephone}
        >
          <PhoneOutlined /> Call
        </Button>
      </div>
      <div>
        <Button
          size='large'
          className='ky_btn ky_btn_bl ky_ntf_btn'
          onClick={() => handleOnClick(NOTIFICATION_TYPES.BUZZ)}
          disabled={!order.customerId}
        >
          <BellOutlined /> Buzz
        </Button>
      </div>
      <div>
        <Button
          size='large'
          className='ky_btn ky_btn_grn ky_ntf_btn'
          onClick={() => handleOnClick(NOTIFICATION_TYPES.SMS)}
          disabled={!order.customerTelephone}
        >
          <MessageOutlined /> SMS
        </Button>
      </div>
      {phoneNumberModal}
    </Space>
  );
};
