import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Col, Input, Radio, Row, Button } from 'antd';
import { LoadingIcon } from './../../components/loading';
import { RADIO } from './constants';
import { getCustomerByCode } from '../actions';
import { notifiableAPICall, notifyError } from './../../components/notification';
import StatusIcon, { TYPE } from '../../components/statusIcon';

const NFC_ENABLED = false;

const NFCAndCusId = forwardRef((Props, ref) => {

    const {
        setCustomer,
        customer,
        setError,
        isError,
        setOrderReady,
        nextOption,
        radioValue,
        setRadioValue,
    } = Props;

    const [customerCode, setCustomerCode] = useState();
    const [isSearch, setIsSearch] = useState(false);
    const [isSearchBtn, setIsSearchBtn] = useState(true);

    useEffect(() => {
        if (!NFC_ENABLED) {
            setRadioValue(RADIO.BTN_TWO);
        }
    }, [setRadioValue]);

    const radioOnChange = (value) => {
        setError(false);
        setCustomerCode();
        setIsSearch(false);
        setRadioValue(value.target.value);
    }

    const onChangeCustomerCode = (value) => {
        setError(false);
        setCustomerCode(value);
    }

    const clear = () => {
        setCustomer()
        setOrderReady(false);
    }

    const codeReset = () => {
        setError(false);
        setCustomerCode();
        setIsSearchBtn(true);
    }

    useImperativeHandle(ref, () => ({
        reset() {
            setCustomerCode();
            setIsSearchBtn(true);
        }
    }));

    const gotoNextOption = () => {
        setRadioValue(RADIO.BTN_ONE);
        nextOption();
    }

    const onSearchCustomer = async (value) => {
        clear();
        if (!value || value.length < 3) {
            notifyError("Code must have 3 or 4 digits!");
            return;
        }
        setIsSearch(true);
        const result = await notifiableAPICall(async () => getCustomerByCode(value.toUpperCase()),
            "Get Customer",
            "Searching",
            "Search completed",
            "Something went wrong please try again!",
            true
        );
        if (!!result) {
            const name = `${result.firstName} ${result.lastName}`;
            setCustomer({ name: name, id: result.customerId })
            setIsSearch(false);
            setOrderReady(true);
            setIsSearchBtn(false);
        }
        else {
            setError(true);
            setIsSearch(false);
            setIsSearchBtn(false);
        }
    }

    const inProgress = isSearch ?
        (
            <Row className="kymt_3">
                <div className="kymr_2 ky_srch_ldng">
                    <LoadingIcon />
                </div>
                <div className="ky_srch_cstmr">Searching customer</div>
            </Row>
        )
        :
        null

    const customerView = !!customer ?
        (
            <div className="ky-msg-wrapper">
                <StatusIcon type={TYPE.SUCCESS} />
                <p className="ky-success-txt">Customer <strong>{customer.name}</strong> was found.</p>
            </div>
        )
        :
        null

    const errorView = isError ?
        (
            <>
                <div className="ky-msg-wrapper">
                    <StatusIcon type={TYPE.FAILED} />
                    <p className='ky-err-txt'>That didn't work, Try again or click for more connection options</p>
                </div>
                <div className='ky_btn_cntnr_spc_btwn ky-w-100'>
                    <Button className='ky_btn ky_btn_blck kyu-fnt-mdm kymr_2 kymt_2' onClick={codeReset}>Retry</Button>
                    <Button className='ky_btn ky_btn_rng kyu-fnt-mdm kymt_2' onClick={gotoNextOption}>Select another option</Button>
                </div>
            </>
        )
        :
        null

    const searchBtnView = isSearchBtn ?
        (
            <Col span={8} className='kymt_2 '>
                <Button className="ky_btn ky_btn_rng kyu-fnt-mdm ky_btn_cstmr_srch ky_btn_lng" onClick={() => onSearchCustomer(customerCode)}>Search</Button>
            </Col>
        )
        :
        null

    const processingView = NFC_ENABLED && radioValue === RADIO.BTN_ONE ?
        (
            <div>
                <Row className="kymt_3">
                    <div className="kymr_2 ky_srch_ldng">
                        <LoadingIcon />
                    </div>
                    <div className="ky_srch_cstmr">NFC Processing</div>
                </Row>
            </div>
        )
        :
        (
            <Col>
                <div className="kymt_2">Customer Id</div>
                <Row>
                    <Col span={16} className="kymt_2">
                        <Input
                            disabled={!isSearchBtn}
                            placeholder='Enter customer code'
                            onChange={(value) => onChangeCustomerCode(value.target.value)}
                            value={customerCode}
                            onPressEnter={(value) => onSearchCustomer(value.target.value)}
                            maxLength={4}
                        />
                    </Col>
                    {
                        searchBtnView
                    }
                </Row>
                {
                    inProgress
                }
                {
                    customerView
                }
                {
                    errorView
                }
            </Col>
        )


    const nfcRadio = NFC_ENABLED ? (
        <>
            <Radio className='kyu-radio-btn' value={RADIO.BTN_ONE}>
                NFC
                </Radio>
            <div className='kyu-radio-btn-dsc'>Ask the customer to tap the phone on the KYUU coaster</div>
        </>
    ) : null;

    const customerRadio = (
        <>
            <Radio className='kyu-radio-btn' value={RADIO.BTN_TWO}>
                Customer Id
                </Radio>
            <div className='kyu-radio-btn-dsc'>Enter customer's unique KYUU Id</div>
        </>
    );

    return (
        <div>
            <Radio.Group onChange={radioOnChange} value={radioValue}>
                {nfcRadio}
                {customerRadio}
            </Radio.Group>
            {
                processingView
            }
        </div>
    );
});

export default NFCAndCusId;
