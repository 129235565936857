import React, { createContext, useReducer } from 'react';
import { Map as IMap } from 'immutable';

const ACTIONS = {
    LOGGED: "logged",
    VENUE_LOADED: "venue_loaded",
    VENUE_SETTINGS_UPDATED: "venue_settings_updated",
    VENUE_SERVICE_PERIODS_UPDATED: "venue_service_periods_updated"
}

const initialState = IMap({
    "id": null,
    "firstName": null,
    "lastName": null,
    "email": null,
    "venueId": null,
    "venueName": null,
    "venueConfigurations": {},
    "sessionReady": false,
    "isVenueAdmin": false,
    "venueServicePeriods": []
});

const sessionStore = createContext(initialState);
const { Provider } = sessionStore;

const isAdminUser = (user) => {
    return !!user["cognito:groups"]?.includes('VenueAdmin');
}

const SessionStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        const { payload, type } = action;
        switch (type) {
            case ACTIONS.LOGGED:
                return state.merge(IMap({
                    venueId: payload["custom:venueId"],
                    email: payload.email,
                    lastName: payload.family_name,
                    firstName: payload.name,
                    id: payload.sub,
                    isVenueAdmin: isAdminUser(payload)
                }));
            case ACTIONS.VENUE_LOADED:
                return state.merge(IMap({
                    venueName: payload.name,
                    venueConfigurations: payload.configurations,
                    venueServicePeriods: payload.servicePeriods,
                    sessionReady: true
                }));
            case ACTIONS.VENUE_SETTINGS_UPDATED:
                return state.merge(IMap({
                    venueConfigurations: payload
                }));
            case ACTIONS.VENUE_SERVICE_PERIODS_UPDATED:
                return state.merge(IMap({
                    venueServicePeriods: payload
                }))
            default:
                throw new Error("action not defined.");
        };
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export { sessionStore, SessionStateProvider, ACTIONS }