import { Col, Menu, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { orderStore } from "../../store/orders";
import { ICONS, ImageIcon } from "../../utils/images";
import { isDesktop } from "../../utils/view";
import { STATUS } from "../orders/actions";
import { ROUTES } from './main';

const SiderBody = ({ routeTo, isVenueAdmin, collapsed }) => {

    const [selectedMenu, setSelectedMenu] = useState(ROUTES.ORDERS);
    const [counts, setCounts] = useState({
        [STATUS.ALL]: 0,
        [STATUS.IN_PROGRESS]: 0,
        [STATUS.WAITING_FOR_PICKUP]: 0,
        [STATUS.COMPLETED]: 0
    })
    const { state } = useContext(orderStore);
    const { orders } = state;

    useEffect(() => {
        setCounts(orders);
    }, [orders]);

    const { pathname } = useLocation();

    useEffect(() => {
        const path = pathname.substr(1);
        if (path !== selectedMenu)
            setSelectedMenu(path);
    }, [pathname, selectedMenu]);

    const getMobileMenuItemLabel = (icon, count, isAnimatable) => (<Row>
        <Col className="ky_menu_icon" span={8}>
            <ImageIcon icon={icon} />
        </Col>
        <Col className={`ky_menu_count ${isAnimatable && count > 0 ? 'animated' : ''}`} span={16}>{count}</Col>
    </Row>);

    const getMenuItemLabel = (title, count = 0, icon, isAnimatable = false) => {
        if (collapsed) return getMobileMenuItemLabel(icon, count, isAnimatable);
        if (isDesktop()) return (<Row>
            <Col className="ky_menu_icon" span={3}>
                <ImageIcon icon={icon} />
            </Col>
            <Col className="ky_menu_title" span={16}>{title}</Col>
            <Col className={`ky_menu_count ${isAnimatable && count > 0 ? 'animated' : ''}`} span={5}>
                {count}</Col>
        </Row>);
        return getMobileMenuItemLabel(icon, count, isAnimatable);
    }

    const getMenuItemTitle = (title, count = 0) => `${title} ${count}`

    return (
        <Menu
            selectedKeys={[selectedMenu]}
            mode="inline"
            theme="dark"
            onClick={({ key }) => routeTo(key)}
            className="ky_menu"
        >
            <Menu.Item key={ROUTES.ORDERS_ALL} className="ky_menu_item" title={getMenuItemTitle("All Orders", counts[STATUS.ALL])}>
                {getMenuItemLabel("All Orders", counts[STATUS.ALL], ICONS.ALL)}
            </Menu.Item>
            <Menu.Item key={ROUTES.ORDERS_IN_PROGRESS} className="ky_menu_item" title={getMenuItemTitle("In Progress", counts[STATUS.IN_PROGRESS])}>
                {getMenuItemLabel("In Progress", counts[STATUS.IN_PROGRESS], ICONS.IN_PROGRESS, true)}
            </Menu.Item>
            <Menu.Item key={ROUTES.ORDERS_WAITING_FOR_PICK_UP} className="ky_menu_item" title={getMenuItemTitle("Waiting for Pickup", counts[STATUS.WAITING_FOR_PICKUP])}>
                {getMenuItemLabel("Waiting for Pickup", counts[STATUS.WAITING_FOR_PICKUP], ICONS.WAITING_FOR_PICKUP, true)}
            </Menu.Item>
            <Menu.Item key={ROUTES.ORDERS_COMPLETED} className="ky_menu_item" title={getMenuItemTitle("Completed", counts[STATUS.COMPLETED])}>
                {getMenuItemLabel("Completed", counts[STATUS.COMPLETED], ICONS.COMPLETED)}
            </Menu.Item>
            {isVenueAdmin &&
                <Menu.Item key={ROUTES.VENUE} className="ky_menu_item" title="Venue Settings">
                    {getMenuItemLabel("Venue Settings", null, ICONS.SETTINGS)}
                </Menu.Item >}
        </Menu >
    )
};

export default SiderBody;