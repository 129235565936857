import { Space, Button } from 'antd';
import { CONFIG } from "../../../config/app-config";
import { STATUS } from '../actions';

export const ActionButtons = ({ order, onStatusButtonClick, onNotifictionButtonClick, isActionButtonsDisabled = false, showDetailViewActions = false }) => {

  const handleOnClick = async (event, status) => {
    event.stopPropagation();
    if (status) {
      onStatusButtonClick(order.orderId, status);
    } else {
      onNotifictionButtonClick();
    }
  };

  const canUndo = (date, now) => date && (now - new Date(date).getTime()) <= CONFIG.stateUndoPeriod;

  const renderButtons = () => {
    const now = new Date().getTime();
    const undoWaitingForPickupState = showDetailViewActions && order.readyAt && canUndo(order.readyAt, now) ? (<Button disabled={isActionButtonsDisabled} className='ky_btn ky_btn_yllw ky_btn_lng' onClick={(event) => handleOnClick(event, STATUS.IN_PROGRESS)}>
      Undo (In Progress)
    </Button>) : null;

    const undoCompleteState = showDetailViewActions && order.pickedUpAt && canUndo(order.pickedUpAt, now) ? (<Button disabled={isActionButtonsDisabled} className='ky_btn ky_btn_yllw ky_btn_lng' onClick={(event) => handleOnClick(event, STATUS.WAITING_FOR_PICKUP)}>
      Undo (Waiting for Pickup)
    </Button>) : null;

    switch (order.status) {
      case STATUS.IN_PROGRESS:
        return (<Button disabled={isActionButtonsDisabled} className='ky_btn ky_btn_bl ky_btn_lng ky_btn_action' onClick={(event) => handleOnClick(event, STATUS.WAITING_FOR_PICKUP)}>
          Ready
        </Button>);
      case STATUS.WAITING_FOR_PICKUP:
        return (
          <Space>
            {undoWaitingForPickupState}
            {!showDetailViewActions && order.beeperNo === undefined && <Button disabled={isActionButtonsDisabled } className='ky_btn ky_btn_yllw ky_btn_lng_notify' onClick={(event) => handleOnClick(event)}>
              Notify
            </Button>}
            <Button disabled={isActionButtonsDisabled} className='ky_btn ky_btn_grn ky_btn_lng ky_btn_action' onClick={(event) => handleOnClick(event, STATUS.COMPLETED)}>
              Picked Up
            </Button>
          </Space>
        );
      case STATUS.COMPLETED:
        return undoCompleteState
      default:
        return null;
    }
  }

  return renderButtons();

};

