import { useContext, useState } from "react";
import { Button, Form, Input, Card } from "antd";
import { useHistory } from "react-router-dom";
import { signIn } from "../../actions/auth";
import { sessionStore } from '../../store/session';
import { Spin } from 'antd';
import { LoadingIcon } from "../components/loading";
import ForgotPasswordDrawer from "./forgotPasswordDrawer";

const Login = ({ setAuthenticated }) => {

  const [requireNewPassword, setRequireNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isMailEntered, setIsMailEntered] = useState(false);
  const history = useHistory();
  const { dispatch } = useContext(sessionStore);

  const login = async ({ newPassword, confirmNewPassword, username, password }) => {
    setLoading(true);
    if (requireNewPassword && newPassword !== confirmNewPassword) {
      setErrorMessage("New password does not match with confirm password");
      setLoading(false);
      return;
    }

    const error = await signIn(username, password, newPassword, dispatch);
    if (error && error === "NEW_PASSWORD_REQUIRED") {
      setRequireNewPassword(true);
      setErrorMessage("Please set a new password");
      setLoading(false);
      return;
    }

    if (error) {
      setErrorMessage(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    setAuthenticated(true);
    history.push('/');
  };

  const newPasswordFieldItems = (requireNewPassword) ? <>
    <Form.Item
      label="New Password"
      name="newPassword"
      rules={[
        {
          required: true,
          message: 'Please input your new password',
        },
      ]}
    >
      <Input.Password placeholder="Type you new password here" disabled={loading ? true : false} />
    </Form.Item>
    <Form.Item
      label="Confirm New Password"
      name="confirmNewPassword"
      rules={[
        {
          required: true,
          message: 'Please re-enter your new password',
        },
      ]}
    >
      <Input.Password placeholder="Type you new password here too" disabled={loading ? true : false} />
    </Form.Item>
  </> : null;

  const errorView = (errorMessage) ? <div className="ky_errmsg kymt_3">{errorMessage}</div> : null;

  return (
    <div className="ky_lgn_vw">
      <div className="ky_frm ky_frm_lgn">
        <div>
          <img
            src="./kyuu-logo.png"
            className="ky_logo ky_logo_lgn"
            alt="Kyuu"
          ></img>
          <Card bordered className="ky_crd ky_crd_lgn">
            <Form name="login" layout="vertical" onFinish={login}>
              {errorView}
              <Form.Item
                className="ky_lbl_lgnfrm"
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username",
                  },
                ]}
              >
                <Input
                  className="kypl_1 ky_int ky_int_lgnfrm"
                  placeholder="Username"
                  disabled={loading ? true : false}
                ></Input>
              </Form.Item>
              <Form.Item
                className="ky_lbl_lgnfrm"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password",
                  },
                ]}
              >
                <Input.Password
                  className="kypl_1 ky_int ky_int_lgnfrm"
                  placeholder="Password"
                  disabled={loading ? true : false}
                ></Input.Password>
              </Form.Item>
              {newPasswordFieldItems}
              <Form.Item className="kymb_1">
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  shape="rounded"
                  className="ky_btn ky_btn_rng ky_btn_wd ky_btn_lgn kymb_0"
                  icon={loading ? <Spin className="ky_spin" indicator={LoadingIcon(14, "white")} /> : null}
                >
                  Sign In
                </Button>
              </Form.Item>
              { !requireNewPassword &&
                <Form.Item className="ky_fgtpwd kymb_1">
                  <Button
                    type="link"
                    className="ky_fgtpwd_btn"
                    onClick={() => setIsForgotPassword(true)}
                  >
                    <span className="ky_fgtpwd_btn_spn">forgot password?</span>
                  </Button>
                </Form.Item>
              }
            </Form>
          </Card>
        </div>
        {isForgotPassword && <ForgotPasswordDrawer isForgotPassword={isForgotPassword} isMailEntered={isMailEntered} setIsMailEntered={setIsMailEntered} setIsForgotPassword={setIsForgotPassword} />}
      </div>
    </div>
  );
}

export default Login;