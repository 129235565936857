import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, TimePicker, Card, Row, Col, Button } from "antd";
import { InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { notifyError } from '../components/notification';
import { toMomentTimeA, TIME_FORMAT_A, toMomentTime24, toTime12aString, generateOrderServicePeriod, toTime24String, toShortDateTimeString } from '../../utils/datetime';

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const DEF_FROM = "08:00:00";
const DEF_TO = "17:00:00";

const ServicePeriodModal = ({ servicePeriod, onCancel, onSave, onDelete }) => {

    const [form] = Form.useForm();
    const { id, name, from, to } = !!servicePeriod ? servicePeriod : {};
    const toMomentTime = (time, def) => time ? toMomentTimeA(time) : toMomentTime24(def);

    const [spFrom, setSpFrom] = useState(toMomentTime(from, DEF_FROM));
    const [spTo, setSpTo] = useState(toMomentTime(to, DEF_TO));

    const [sp, setSp] = useState(null);

    useEffect(() => {
        if (!spFrom || !spTo) return;
        const _sp = generateOrderServicePeriod(new Date(), {
            type: "exact",
            from: toTime24String(spFrom),
            to: toTime24String(spTo)
        });
        setSp(_sp);
    }, [spFrom, spTo]);

    const save = () => {
        form.validateFields()
            .then(values => {
                const timePeriod = {
                    name: values.name,
                    from: toTime24String(values.from),
                    to: toTime24String(values.to),
                    type: "exact"
                }

                if (!!servicePeriod) {
                    timePeriod.id = servicePeriod.id;
                }
                onSave(timePeriod);
            })
            .catch((err) => {
                notifyError("Some Missing Fields Detected!");
                throw err;
            })
    }

    const _onDelete = (id) => {
        Modal.confirm({
            title: <div className="ky_cnf_title">Are you sure delete this service period?</div>,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            className: 'Ky_cnfm_md',
            okButtonProps: {
                className: 'ky_btn ky_btn_rd'
            },
            cancelButtonProps: {
                className: 'ky_btn ky_btn_drk'
            },
            onOk() {
                onDelete(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const onValuesChange = (changed, _) => {
        if (changed.from) {
            setSpFrom(changed.from);
        }

        if (changed.to) {
            setSpTo(changed.to);
        }
    };

    const deleteBtn = !!servicePeriod ?
        <Button className="ky_btn ky_btn_rd" onClick={() => _onDelete(id)}>Delete</Button>
        :
        null;



    return (
        <Modal
            visible={true}
            onCancel={onCancel}
            onOk={onSave}
            title="Service Period"
            wrapClassName="ky_modal_nv ky_srv_mdl"
            footer={null}
        >
            <Form {...layout} form={form}
                initialValues={{ name, from: spFrom, to: spTo }}
                onValuesChange={onValuesChange}
            >
                <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter a name" }]}>
                    <Input placeholder="Service Period Name" />
                </Form.Item>
                <Form.Item label="From" name="from" rules={[{ required: true, message: "Please select time" }]}>
                    <TimePicker use12Hours format={TIME_FORMAT_A} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="To" name="to" rules={[{ required: true, message: "Please select time" }]}>
                    <TimePicker use12Hours format={TIME_FORMAT_A} style={{ width: '100%' }} />
                </Form.Item>
                {sp && <Card className="ky_srv_mdl_tip">
                    <Row>
                        <Col span={2} className="icon_hldr">
                            <InfoCircleOutlined className="icon" />
                        </Col>
                        <Col span={22}>
                            Service period will be from <b>{`${toTime12aString(spFrom)}`}</b> to <b>{toTime12aString(spTo)}</b>, for an example <b>{toShortDateTimeString(sp.from)} </b> to <b>{toShortDateTimeString(sp.to)}</b>.
                        </Col>
                    </Row>
                </Card> }
            </Form>
            <Row className="ky_srv_footer">
                {deleteBtn}
                <Button className=" ky_btn ky_btn_drk" onClick={() => onCancel()}>Cancel</Button>
                <Button className="ky_btn ky_btn_grn" onClick={() => save(form)}>Save</Button>
            </Row>
        </Modal>
    );
}

export default ServicePeriodModal;