import { Card, Row, Col } from 'antd';
import { ActionButtons } from './actionButtons';
import { NotifyButtons } from './notifyButtons';
import { STATUS } from '../actions';
import { toLongDateTimeString } from '../../../utils/datetime';
import { truncateOrderNo } from '../../../utils/commons';

const colSpan = 12;

const OrderDetailsBody = ({ order, venueId, updateStatus, isActionButtonsDisabled, onBuzz, shouldOrderNoTruncate }) => {

  const notificationButtons = order.status === STATUS.WAITING_FOR_PICKUP && order.beeperNo === undefined ?
    (<NotifyButtons order={order} venueId={venueId} onBuzz={onBuzz} />)
    : null;

  const firstName = order.customerFirstName || '';
  const lastName = order.customerLastName || '';
  let fullName = `${firstName} ${lastName}`;
  fullName = fullName.trim() ? fullName : 'N/A';

  return (
    <div>
      <Card
        title={`Order: ${truncateOrderNo(order.orderNo, shouldOrderNoTruncate)}`}
        headStyle={{ textAlign: 'center' }}
        bordered={false}
        className='ky_crd_tbl_ordrs'
      >
        <Row className='ky_sht_rw kypt_5 kypb_5'>
          <Col span={colSpan}>Order No</Col>
          <Col span={colSpan}>{truncateOrderNo(order.orderNo, shouldOrderNoTruncate)}</Col>
        </Row>
        <Row className='ky_sht_rw kypt_5 kypb_5'>
          <Col span={colSpan}>Table No</Col>
          <Col span={colSpan}>{order.tableNo || 'N/A'}</Col>
        </Row>
        <Row className='ky_sht_rw kypt_5 kypb_5'>
          <Col span={colSpan}>Group Name</Col>
          <Col span={colSpan}>{order.groupName || 'N/A'}</Col>
        </Row>
        <Row className='ky_sht_rw kypt_5 kypb_5'>
          <Col span={colSpan}>Customer Name</Col>
          <Col span={colSpan}>{`${fullName}`}</Col>
        </Row>
        <Row className='ky_sht_rw kypt_5 kypb_5'>
          <Col span={colSpan}>Created At</Col>
          <Col span={colSpan}>{toLongDateTimeString(order.createdAt)}</Col>
        </Row>
        {
          order.beeperNo !== undefined ?
            <Row className='ky_sht_rw kypt_5 kypb_5'>
              <Col span={colSpan}>Beeper No</Col>
              <Col span={colSpan}>{order.beeperNo}</Col>
            </Row>
            : null
        }
        {order.status === STATUS.WAITING_FOR_PICKUP || order.status === STATUS.COMPLETED
          ? <Row className='ky_sht_rw kypt_5 kypb_5'>
            <Col span={colSpan}>Prepared At</Col>
            <Col span={colSpan}>{toLongDateTimeString(order.readyAt)}</Col>
          </Row> : null}
        {order.status === STATUS.COMPLETED
          ? <Row className='ky_sht_rw kypt_5 kypb_5'>
            <Col span={colSpan}>Picked-up At</Col>
            <Col span={colSpan}>{toLongDateTimeString(order.pickedUpAt)}</Col>
          </Row> : null}
      </Card>
      {notificationButtons}
      <div className='ky_sht_btns'>
        <ActionButtons order={order} isActionButtonsDisabled={isActionButtonsDisabled} onStatusButtonClick={updateStatus} showDetailViewActions={true} />
      </div>
    </div>
  );
}

export default OrderDetailsBody;