import React, { useContext, useEffect, useState } from "react";
import { updateVenueSettings, updateVenueServicePeriod, deleteVenueServicePeriod } from "./actions";
import { sessionStore } from "../../store/session";
import { Card, Switch, Divider, Row, Col, Button, Tag, notification } from "antd";
import { Loading } from "../components/loading";
import { isObjectEmpty } from "../../utils/commons";
import { notifiableAPICall } from "../components/notification";
import ServicePeriodModal from "./servicePeriodModal";
import { to24to12aTimeString } from "../../utils/datetime";
import { Prompt } from 'react-router'

const labelWidth = 16;
const valueWidth = 8;

const Venue = () => {
    const [venueSettingsLoaded, setVenueSettingsLoaded] = useState(false);
    const [enableGOrders, setenableGOrders] = useState(false);
    const [enableGAutomaticGeneration, setEnableGAutomaticGeneration] = useState(false);
    const [enableTableForCustomers, setEnableTableForCustomers] = useState(false);
    const [enableOrderNoAutomaticGeneration, setEnableOrderNoAutomaticGeneration] = useState(false);
    const [isServicePeriodModalOpen, setServicePeriodModalOpen] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const { state, dispatch } = useContext(sessionStore);
    const { venueConfigurations, venueId, venueServicePeriods } = state.toJS();

    const [canLeavePage, setCanLeavePage] = useState(true);
    const [isSaving, setSaving] = useState(false);

    useEffect(() => {
        function initSettings() {
            setVenueSettingsLoaded(true);
            setenableGOrders(venueConfigurations.groupEnable);
            setEnableGAutomaticGeneration(venueConfigurations.groupAutoGenerate);
            setEnableTableForCustomers(venueConfigurations.tableNoEnable);
            setEnableOrderNoAutomaticGeneration(venueConfigurations.orderNoAutoGenerate);
        }

        if (venueConfigurations && !isObjectEmpty(venueConfigurations) && !venueSettingsLoaded) {
            initSettings();
        }
    }, [venueConfigurations, venueSettingsLoaded]);

    useEffect(() => {
        function handler(ev) {
            if (canLeavePage) return;
            ev.preventDefault();
            return ev.returnValue = 'You have unsaved changes, are you sure you want to leave?';
        };
        window.addEventListener("beforeunload", handler);
        return () => window.removeEventListener("beforeunload", handler);
    }, [canLeavePage]);

    useEffect(() => {
        if (!canLeavePage && !isSaving) {
            notification.warning({ key: 'save-settings', message: "To save new changes, please click on the 'save settings' button" });
        } else {
            notification.close('save-settings');
        }
    }, [canLeavePage, isSaving]);

    useEffect(() => {
        if (!venueSettingsLoaded) return;
        const { groupEnable, groupAutoGenerate, tableNoEnable, orderNoAutoGenerate } = venueConfigurations;
        if (groupEnable === enableGOrders &&
            groupAutoGenerate === enableGAutomaticGeneration &&
            tableNoEnable === enableTableForCustomers &&
            orderNoAutoGenerate === enableOrderNoAutomaticGeneration) {
            setCanLeavePage(true);
        } else {
            setCanLeavePage(false);
        }
    }, [venueSettingsLoaded, venueConfigurations, enableGOrders, enableGAutomaticGeneration, enableTableForCustomers, enableOrderNoAutomaticGeneration]);

    const saveSettings = async () => {
        setSaving(true);
        const venueConfigurations = {
            "groupAutoGenerate": enableGAutomaticGeneration,
            "orderNoAutoGenerate": enableOrderNoAutomaticGeneration,
            "tableNoEnable": enableTableForCustomers,
            "groupEnable": enableGOrders,
        };
        notification.close('save-settings');
        await notifiableAPICall(async () => await updateVenueSettings(venueId, venueConfigurations, dispatch),
            "venue-settings-update",
            "Please wait",
            "Venue settings were saved successfully",
            "Error in saving venue settings, Please retry again.");
        setCanLeavePage(true);
        setSaving(false)
    };

    if (!venueSettingsLoaded) return <Loading />;

    const onChangeenableGOrders = () => {
        if (enableGOrders) {
            setEnableGAutomaticGeneration(false);
        }
        setenableGOrders(!enableGOrders);
    }

    const onServiceModalClose = () => {
        setServicePeriodModalOpen(false);
        setSelectedPeriod(null);
    }

    const onServiceModalSave = async (timePeriod) => {
        let successMsg = "Service Period added successfully."

        if (!!selectedPeriod) {
            successMsg = "Service Period updated successfully."
        }

        await notifiableAPICall(() => updateVenueServicePeriod(venueId, timePeriod, dispatch),
            "save_time_period",
            "Please wait...",
            successMsg,
            "Something went wrong.",
            true);

        setServicePeriodModalOpen(false);
        setSelectedPeriod(null);
    }

    const openSelectedModal = (period) => {
        setSelectedPeriod(period);
        setServicePeriodModalOpen(true);
    }

    const onServiceModalDelete = async (servicePeriodId) => {
        await notifiableAPICall(() => deleteVenueServicePeriod(venueId, servicePeriodId, dispatch),
            "save_time_period",
            "Please wait...",
            "Service Period deleted successfully.",
            "Something went wrong.",
            true);

        setServicePeriodModalOpen(false);
        setSelectedPeriod(null);
    }

    const settingsCard = (
        <Card bordered={false} className="ky_crd_vn_stng">
            <Prompt
                when={!canLeavePage}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            <Divider></Divider>
            <div className="ky_grd_nm ky_title_5">Orders</div>
            <Row className="ky_brdr_tp">
                <Col span={labelWidth}>Automatically generate a Kyuu number</Col>
                <Col span={valueWidth}>
                    <Switch
                        size="small"
                        onChange={() => setEnableOrderNoAutomaticGeneration(!enableOrderNoAutomaticGeneration)}
                        checked={enableOrderNoAutomaticGeneration}
                        className="ky_swtch"
                    />
                </Col>
            </Row>
            <Divider></Divider>
            <div className="ky_grd_nm ky_title_5">Table</div>
            <Row className="ky_brdr_tp">
                <Col span={labelWidth}>Enable tables for customers </Col>
                <Col span={valueWidth}>
                    <Switch
                        size="small"
                        onChange={() =>
                            setEnableTableForCustomers(!enableTableForCustomers)
                        }
                        checked={enableTableForCustomers}
                        className="ky_swtch"
                    />
                </Col>
            </Row>
            <Divider></Divider>
            <div className="ky_grd_nm ky_title_5">Group</div>
            <Row className="ky_brdr_tp">
                <Col span={labelWidth}>Enable group orders </Col>
                <Col span={valueWidth}>
                    <Switch
                        size="small"
                        onChange={() => onChangeenableGOrders()}
                        checked={enableGOrders}
                        className="ky_swtch"
                    />
                </Col>
            </Row>
            <Row className="ky_brdr_tp">
                <Col span={labelWidth}>Enable automatic group name generation</Col>
                <Col span={valueWidth}>
                    <Switch
                        size="small"
                        onChange={() =>
                            setEnableGAutomaticGeneration(!enableGAutomaticGeneration)
                        }
                        checked={enableGAutomaticGeneration}
                        className="ky_swtch"
                        disabled={!enableGOrders}
                    />
                </Col>
            </Row>
            <Divider></Divider>
        </Card>
    );

    const servicePeriodCard = (
        <Card bordered={false} className="ky_crd_vn_stng dyn_hgt">
            <Row>
                <Col span={16} className="ky_title_5">Service Periods</Col>
                <Col span={8}>
                    <Button
                        className="ky_vn_btn ky_btn ky_btn_rng ky_btn_lng"
                        onClick={() => setServicePeriodModalOpen(true)}
                    >Add New
                    </Button>
                </Col>
                <Divider />
            </Row>
            <Row>
                {
                    venueServicePeriods.map(servicePeriod =>
                        <Tag key={servicePeriod.id} className="ky_srv_prd_chp" color="red"
                            onClick={() => openSelectedModal(servicePeriod)}>
                            <Row className="title">{servicePeriod.name}</Row>
                            <Row
                                className="time">{to24to12aTimeString(servicePeriod.from)} to {to24to12aTimeString(servicePeriod.to)}</Row>
                        </Tag>
                    )
                }
            </Row>
        </Card>
    );

    const serviceModal = isServicePeriodModalOpen ? <ServicePeriodModal
        onCancel={onServiceModalClose}
        onSave={onServiceModalSave}
        servicePeriod={selectedPeriod}
        onDelete={onServiceModalDelete}
    /> : null;

    return (
        <>
            {serviceModal}
            <div className="ky_title_4 ky_cntr">Venue Settings</div>
            {settingsCard}
            {servicePeriodCard}
            <div className="ky_crd_vn_stng">
                <Button
                    className={`ky_vn_btn ky_btn ky_btn_rng ky_btn_lng ${!canLeavePage ? 'ky_gt_fcs' : ''}`}
                    htmlType="submit"
                    onClick={saveSettings}
                >
                    Save Settings
                </Button>
            </div>
        </>
    );
};

export default Venue;
