export const TYPE = {
    FAILED: 0,
    SUCCESS: 1,
}

const StatusIcon = (props) => {
    const {type} = props;
    return ( <>{
            type === TYPE.SUCCESS ? 
                <div className="animation-ctn">
                    <div className="ky-svg-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                            <g fill="none" stroke="#22AE73" stroke-width="2"> 
                            <circle cx="77" cy="77" r="72" style={{strokeDasharray: "480px, 480px", strokeDashoffset: "960px"}}></circle>
                            <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{strokeDasharray: "480px, 480px", strokeDashoffset: "960px"}}></circle>
                            <polyline className="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{strokeDasharray: "100px, 100px", strokeDashoffset: "200px"}}/>   
                            </g> 
                        </svg>
                    </div>
                </div> :
                <div className="animation-ctn">
                    <div className="ky-svg-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">  
                            <g fill="none" stroke="#F44812" stroke-width="2"> 
                                <circle cx="77" cy="77" r="72" style={{strokeDasharray: "480px, 480px", strokeDashoffset: "960px"}}></circle>
                                <circle id="colored" fill="#F44812" cx="77" cy="77" r="72" style={{strokeDasharray: "480px, 480px", strokeDashoffset: "960px"}}></circle>
                                {/* <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8  112.2,77.8 " style={{strokeDasharray: "100px, 100px", strokeDashoffset: "200px"}}/>    */}
                                <polygon fill="#ffffff" points="108.5,102.2 101.2,109.5 77.5,85.8 53.8,109.5 46.5,102.2 70.2,78.5 46.5,54.8 53.8,47.5 77.5,71.2 101.2,47.5 108.5,54.8 84.8,78.5"/>
                            </g> 
                        </svg>
                    </div>
                </div>
        }</>
    )
}

export default StatusIcon;