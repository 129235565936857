import { useEffect, useState, useContext, useCallback } from 'react';
import { Drawer, Divider, Col, Row } from 'antd';
import { isMobile, isTablet, canScanQR } from '../../../utils/view';
import { INPUT_TYPE, DRAWER_WIDTH, STEPS as ALL_STEPS, OPTION_LEVELS, RADIO } from './constants';
import ConnectUserView from './connectUserView';
import { notifiableAPICall, notifyError } from '../../components/notification';
import { sessionStore } from '../../../store/session';
import { getSuggestedOrderNo, getSuggestGroup, getVenueGroups, getVenueTables } from '../actions';
import OrderNo from './orderNoField';
import TableNo from './tableNoField';
import GroupNo from './groupNoField';

const NewOrderDrawer = ({ visible, onClose, onOrderAdd }) => {
    const [width, setWidth] = useState(DRAWER_WIDTH.desktop);
    const [formStep, setFormStep] = useState(1);
    const [orderNo, setOrderNo] = useState();
    const [tableNo, setTableNo] = useState();
    const [tableId, setTableId] = useState();
    const [existingTable, setExistingTable] = useState(false);
    const [existingTableNos, setExistingTableNos] = useState([]);
    const [groupName, setGroupName] = useState();
    const [groupId, setGroupId] = useState();
    const [existingGroup, setExistingGroup] = useState(false);
    const [existingGroupNos, setExistingGroupNos] = useState(false);
    const [steps, setSteps] = useState(ALL_STEPS['OTGC']);
    const [groupAutoGenerateSwitch, setGroupAutoGenerateSwitch] = useState(false);
    const [jumpStep, setJumpStep] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [isError, setError] = useState();
    const [isOrderReady, setOrderReady] = useState(false);
    const [orderReadyDisable, setOrderReadyDisable] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [withNoCustomer, setWithNoCustomer] = useState(false);
    const [beeperValue, setBeeperValue] = useState();
    const [isRetry, setIsRetry] = useState(false);
    const [prevFieldValue, setPrevFieldValue] = useState();
    const [isOrderNoGenerating, setOrderNoGenerating] = useState(false);

    const [selectedConnectCustomerOption, setSelectedConnectCustomerOption] = useState(RADIO.BTN_ONE);

    const initialOptionLevel = canScanQR() ? OPTION_LEVELS.QR_SCANNER : OPTION_LEVELS.NFC_CUS_ID;
    const [optionLevel, setOptionLevel] = useState(initialOptionLevel);

    const { state } = useContext(sessionStore);
    const { venueId, venueConfigurations } = state.toJS();
    const { groupEnable, tableNoEnable, groupAutoGenerate, orderNoAutoGenerate } = venueConfigurations;

    useEffect(() => {
        const setDrawerWidth = () => {
            if (isTablet()) {
                setWidth(DRAWER_WIDTH.tablet);
            } else if (isMobile()) {
                setWidth(DRAWER_WIDTH.mobile);
            } else {
                setWidth(DRAWER_WIDTH.desktop);
            }
        }

        setDrawerWidth();
    }, []);

    useEffect(() => {
        const getActiveSteps = (groupEnable, tableNoEnable) => {
            let stepKey = 'O';
            if (tableNoEnable) stepKey += 'T';
            if (groupEnable) stepKey += 'G';
            stepKey += 'C';

            return ALL_STEPS[stepKey];
        }
        setSteps(getActiveSteps(groupEnable, tableNoEnable));
    }, [groupEnable, tableNoEnable]);

    const nextStep = useCallback(({ value, type, jumpStep, skip = false }) => {
        if (jumpStep) {
            setFormStep(jumpStep);
            setJumpStep(null);
            return;
        }
        if (skip) {
            setFormStep(formStep => formStep + 1);
            return;
        }
        if (!!value) {
            setFormStep(formStep => formStep + 1);
            return;
        }
        notifyError(`${type} can't be empty!`);
    }, []);

    const nextStepForOrderNo = useCallback(({ value, type, jumpStep }) => {
        if (jumpStep && !!value) {
            setFormStep(jumpStep);
            setJumpStep(null);
            return;
        }
        if (!!value) {
            setFormStep(formStep => formStep + 1);
            return;
        }
        notifyError(`${type} can't be empty!`);
    }, []);

    const onOrderNoAutoGenerate = useCallback(async () => {
        try {
            setOrderNoGenerating(true);
            const value = await notifiableAPICall(async () => await getSuggestedOrderNo(venueId),
                "Getting order no.",
                "Please wait.",
                null,
                "Something went wrong please try again!",
                true
            );
            setOrderNo(value);
            nextStep({ value: value, type: INPUT_TYPE.OrderNo });
        } finally {
            setOrderNoGenerating(false);
        }
    }, [nextStep, venueId])

    useEffect(() => {
        if (orderNoAutoGenerate) {
            onOrderNoAutoGenerate();
        }
    }, [orderNoAutoGenerate, onOrderNoAutoGenerate]);

    const onStep = (value) => {
        setFormStep(value)
    }

    const onChange = (setValue, value) => {
        setValue(value);
    }

    const onOrderNoCancel = () => {
        onChange(setOrderNo, prevFieldValue);
        setPrevFieldValue(undefined);
        nextStep({ type: INPUT_TYPE.OrderNo, skip: false, jumpStep });
    }

    const onTableNoCancel = (isCancel) => {
        setTableId(undefined);
        setTableNo(undefined);
        if (isCancel) {
            setTableNo(prevFieldValue.tableNo);
            setTableId(prevFieldValue.tableId);
            setPrevFieldValue(undefined);
        }
        nextStep({ type: INPUT_TYPE.TableNo, jumpStep, skip: true });
    }

    const onGroupNoCancel = (isCancel) => {
        setGroupName(undefined);
        setGroupId(undefined);
        if (isCancel) {
            setGroupName(prevFieldValue.groupName);
            setGroupId(prevFieldValue.groupId);
            setPrevFieldValue(undefined);
        }
        nextStep({ type: INPUT_TYPE.GroupNo, jumpStep, skip: true });
    }

    const onSelect = (setName, setId, option) => {
        setName(option.label);
        setId(option.value);
    }

    const onChangeExistingTable = async (value) => {
        onChange(setExistingTable, value);

        if (value) {
            let tableNos = await notifiableAPICall(async () => getVenueTables(venueId),
                "Getting existing tables.",
                "Please wait.",
                null,
                "Something went wrong please try again!",
                true
            );
            tableNos = tableNos.map((obj) => {
                const tab = {
                    label: obj.name,
                    value: obj.tableId
                }
                return tab;
            })
            setExistingTableNos(tableNos);
        } else {
            setTableNo();
            setTableId();
            setExistingTableNos();
        }
    }

    const onChangeExistingGroup = async (value) => {
        onChange(setExistingGroup, value);

        if (value) {
            let groupNos = await notifiableAPICall(async () => getVenueGroups(venueId),
                "Getting existing groups.",
                "Please wait.",
                null,
                "Something went wrong please try again!",
                true
            );
            groupNos = groupNos.map((obj) => {
                const grp = {
                    label: obj.name,
                    value: obj.groupId
                }
                return grp;
            });
            setExistingGroupNos(groupNos);
        } else {
            setGroupName();
            setGroupId();
            setExistingGroupNos();
        }
    }

    const onChangeGroupAutoGenerate = async (value) => {
        setGroupAutoGenerateSwitch(value);
        if (value) {
            let generatedGroup = await notifiableAPICall(async () => getSuggestGroup(venueId),
                "Generating Group Name",
                "Generating group name.",
                null,
                "Something went wrong please try again!",
                true
            );
            setGroupName(generatedGroup.name);
            setGroupId(generatedGroup.groupId);
        } else {
            setGroupName();
            setGroupId();
        }
    }

    const clearOrder = () => {
        setFormStep(steps.ORDER_NO);
        setOrderNo();
        setTableNo();
        setGroupName();
        setGroupId();
        setTableId();
        setExistingGroup();
        setExistingTable();
    }

    const onEdit = (step, prevValue) => {
        setPrevFieldValue(prevValue);
        setJumpStep(formStep);
        onStep(step);
    }

    const connectUserStepView = formStep === steps.CONNECT_CUSTOMER ?
        (<ConnectUserView
            orderNo={orderNo}
            tableNo={tableNo}
            groupName={groupName}
            tableId={tableId}
            groupId={groupId}
            drawerClose={onClose}
            clearOrder={clearOrder}
            onOrderAdd={onOrderAdd}
            customer={customer}
            setCustomer={setCustomer}
            optionLevel={optionLevel}
            setOptionLevel={setOptionLevel}
            initialOptionLevel={initialOptionLevel}
            isError={isError}
            setError={setError}
            isOrderReady={isOrderReady}
            setOrderReady={setOrderReady}
            orderReadyDisable={orderReadyDisable}
            setOrderReadyDisable={setOrderReadyDisable}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            withNoCustomer={withNoCustomer}
            setWithNoCustomer={setWithNoCustomer}
            beeperValue={beeperValue}
            setBeeperValue={setBeeperValue}
            isRetry={isRetry}
            setIsRetry={setIsRetry}
            selectedConnectCustomerOption={selectedConnectCustomerOption}
            setSelectedConnectCustomerOption={setSelectedConnectCustomerOption}
        />) : null;

    return (
        <Drawer
            placement='left'
            onClose={onClose}
            visible={visible}
            width={width}
            className="ky_drwr"
        >
            <div>
                <Row className='kyu-rw-ttl'>
                    <p className='kyu-txt kyu-fnt-lrg ky_nwordr_txt'>Add New Order</p>
                </Row>
                <Divider />
                <Row className='kyu-rw'>
                    <OrderNo
                        steps={steps}
                        formStep={formStep}
                        orderNo={orderNo}
                        onChange={(value) => onChange(setOrderNo, value.target.value)}
                        onAdd={() => nextStepForOrderNo({ value: orderNo, type: INPUT_TYPE.OrderNo, jumpStep })}
                        onEdit={() => onEdit(steps.ORDER_NO, orderNo)}
                        onCancel={() => onOrderNoCancel()}
                        isEditing={!!jumpStep}
                        shouldOrderNoTruncate={venueConfigurations.orderNoAutoGenerate}
                        isDisbaled={isOrderNoGenerating}
                    />
                </Row>
                <Divider />
                <TableNo
                    tableNoEnable={tableNoEnable}
                    formStep={formStep}
                    steps={steps}
                    existingTable={existingTable}
                    onChangeExistingTable={(value) => onChangeExistingTable(value)}
                    tableNo={tableNo}
                    existingTableNos={existingTableNos}
                    onTableSelect={(value, option) => onSelect(setTableNo, setTableId, option)}
                    onTableChange={(value) => onChange(setTableNo, value)}
                    onAdd={() => nextStep({ value: tableNo, type: INPUT_TYPE.TableNo, jumpStep })}
                    onSkip={(isCancel) => onTableNoCancel(isCancel)}
                    onEdit={() => onEdit(steps.TABLE_NO, { tableNo, tableId })}
                    isEditing={!!jumpStep}
                />
                <GroupNo
                    groupEnable={groupEnable}
                    groupAutoGenerate={groupAutoGenerate}
                    groupAutoGenerateSwitch={groupAutoGenerateSwitch}
                    onChangeGroupAutoGenerate={(value) => onChangeGroupAutoGenerate(value)}
                    formStep={formStep}
                    steps={steps}
                    existingGroup={existingGroup}
                    onChangeExistingGroup={(value) => onChangeExistingGroup(value)}
                    groupName={groupName}
                    existingGroupNos={existingGroupNos}
                    onGroupSelect={(value, option) => onSelect(setGroupName, setGroupId, option)}
                    onGroupChange={(value) => onChange(setGroupName, value)}
                    onAdd={() => nextStep({ value: groupName, type: INPUT_TYPE.GroupNo, jumpStep })}
                    onSkip={(isCancel) => onGroupNoCancel(isCancel)}
                    onEdit={() => onEdit(steps.GROUP_NO, { groupName, groupId })}
                    isEditing={!!jumpStep}
                />
                <Row className='kyu-rw'>
                    <Col className='kyu-npt-cntnr'>
                        <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Connect customer</p>
                        {
                            connectUserStepView
                        }
                    </Col>
                </Row>
            </div>
            <br />
        </Drawer>
    );
}

export default NewOrderDrawer;
