import React, { useContext, useEffect, useRef, useState } from "react";
import AddOrder from "./addOrder/addOrder";
import SearchOrders from './searchOrders/searchOrders';
import { canScanQR, isNotIOSSafari } from "../../utils/view";
import { notifyWarning } from "../components/notification";
import { STATUS } from "./actions";
import { Button, DatePicker, notification, Row, Select, Switch } from "antd";
import OrderTable from "./details/orderTable";
import moment from "moment";
import { sessionStore } from '../../store/session';
import { generateOrderDatePeriod, generateOrderServicePeriod, DISPLAY_DATE_FORMAT, to24to12aTimeString, toDateString } from '../../utils/datetime';
import { useHistory, useLocation } from "react-router";
import { ReloadOutlined } from '@ant-design/icons';

const Option = Select.Option;
const { RangePicker } = DatePicker;

function useQuery() {
    return new URLSearchParams(useLocation().search);
};

const Orders = ({ state = STATUS.ALL }) => {
    const [newOrder, setNewOrder] = useState(null);
    const [searchPhrase, setSearchPhrase] = useState(null);
    const [activeState, setActiveState] = useState(STATUS.ALL);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedServicePeriod, setSelectedServicePeriod] = useState(null);
    const [servicePeriod, setServicePeriod] = useState(null);
    const [isTimeFilter, setIsTimeFilter] = useState(true);
    const [datePeriod, setDatePeriod] = useState(null);
    const [defaultDateRange, setDefaultDateRange] = useState([])

    const { state: session } = useContext(sessionStore);
    const { venueServicePeriods } = session.toJS();

    const query = useQuery();
    const history = useHistory();

    const orderTableRef = useRef(null);

    useEffect(() => {
        if (selectedDate) return;
        const date = query.get("date") || toDateString();
        setSelectedDate(new moment(date));
    }, [query, selectedDate]);

    useEffect(() => {
        if (venueServicePeriods.length === 0 || selectedServicePeriod) return;
        let spId = query.get("sp");
        let sp = venueServicePeriods[0];
        if (spId) sp = venueServicePeriods.find(v => v.id === spId);
        setSelectedServicePeriod(sp);
    }, [venueServicePeriods, selectedServicePeriod, query]);

    useEffect(() => {
        if (selectedDate && selectedServicePeriod) {
            const generatedServicePeriod = generateOrderServicePeriod(selectedDate.toDate(), selectedServicePeriod);
            setServicePeriod(generatedServicePeriod);
            history.push({ search: `?date=${toDateString(selectedDate)}&sp=${selectedServicePeriod.id}` })
        }
    }, [selectedServicePeriod, selectedDate, history]);

    useEffect(() => {
        let active = STATUS.ALL;
        switch (state) {
            case STATUS.ALL:
            case STATUS.IN_PROGRESS:
            case STATUS.WAITING_FOR_PICKUP:
            case STATUS.COMPLETED:
                active = state;
                break;
            default:
                active = STATUS.ALL;
        }
        setActiveState(active);
    }, [state]);

    useEffect(() => {
        if (!canScanQR()) {
            let message = "QR Scanning is not available in this browser due to technical issue.";
            if (isNotIOSSafari()) {
                message = message.concat(" Please use Safari browser to use QR scanning.");
            }
            notifyWarning(message, 10);
        }
    }, []);

    const notifyIfOrderWillNotList = () => {
        const from = isTimeFilter ? servicePeriod.from : datePeriod.from;
        const to = isTimeFilter ? servicePeriod.to : datePeriod.to;
        const isInRange = moment().isBetween(from, to);
        if (!isInRange)
            notification.warning({
                message: 'Selected Service Period may miss latest orders.',
                duration: 7,
                description:
                    'The selected service period may not show the latest orders. To see the latest orders, Please select an appropriate service period.',
            });
    };

    const onDateChange = (newDate) => {
        setSelectedDate(newDate);
    }

    const onServicePeriodChange = (id) => {
        const selected = venueServicePeriods.find(sp => sp.id === id);
        setSelectedServicePeriod(selected);
    }

    const onReload = () => {
        if (orderTableRef.current) orderTableRef.current.reload();
    }

    const onTimePickerToggle = (isTime) => {
        setIsTimeFilter(isTime);
        if (!isTime) {
            const from = moment(servicePeriod.from).startOf('day');
            const to = moment(servicePeriod.to).endOf('day');
            const range = generateOrderDatePeriod([from, to]);
            setDatePeriod(range);
            setDefaultDateRange([range.from, moment(range.to)])
        }
    }

    const onDateRangeSelect = (value) => {
        setDatePeriod(generateOrderDatePeriod(value));
    }

    const renderBody = (<div className="ky_srch_tbbdy ky_tb_nv ky_order_tbl">
        {(searchPhrase) ?
            (isTimeFilter) ? <OrderTable servicePeriod={servicePeriod} category={activeState} newOrder={newOrder} searchPhrase={searchPhrase} />
                : <OrderTable servicePeriod={datePeriod} category={activeState} newOrder={newOrder} searchPhrase={searchPhrase} />

            : (isTimeFilter) ? <OrderTable ref={orderTableRef} servicePeriod={servicePeriod} category={activeState} newOrder={newOrder} />
                : <OrderTable ref={orderTableRef} servicePeriod={datePeriod} category={activeState} newOrder={newOrder} />
        }
    </div>);

    const onOrderAdding = (order) => {
        setNewOrder(order);
        notifyIfOrderWillNotList()
    };

    const mainFilter = isTimeFilter ?
        <>
            <DatePicker
                disabledDate={(cd) => cd.isAfter(moment())}
                defaultValue={selectedDate}
                format={DISPLAY_DATE_FORMAT}
                className="ky_svc_dt_pck"
                onChange={onDateChange} />
            <Select className="ky_svc_slt" value={selectedServicePeriod?.id} onChange={onServicePeriodChange}>
                {venueServicePeriods.map(sp => <Option key={sp.id} value={sp.id}>{`${sp.name} (${to24to12aTimeString(sp.from)} to ${to24to12aTimeString(sp.to)})`}</Option>)}
            </Select>
        </>
        :
        <>
            <RangePicker
                disabledDate={d => d.isAfter(moment(), 'day')}
                format={DISPLAY_DATE_FORMAT}
                defaultPickerValue={defaultDateRange}
                defaultValue={defaultDateRange}
                onChange={onDateRangeSelect}
                showTime={false}
                className="ky_svc_dt_rng_pck"
            />
        </>

    if (!servicePeriod) return null;
    return (
        <>
            <div className="ky_odr_header_col left">
                <Row>
                    <div className="ky_svc_title">
                        <h4>Service Period</h4>
                    </div>
                    {mainFilter}
                    <Switch className="ky_swtch_btn" checkedChildren="Time Range" unCheckedChildren="Date Range" defaultChecked checked={isTimeFilter} onChange={onTimePickerToggle} />
                    <Button className="ky_rld_btn" onClick={onReload}><ReloadOutlined /></Button>
                </Row>
                <Row className="ky_odr_srch_wrp">
                    <SearchOrders onSearch={setSearchPhrase} />
                </Row>
            </div>
            <div className="ky_odr_header_col right">
                <AddOrder onOrderAdd={onOrderAdding} />
            </div>
            {renderBody}
        </>
    );
};

export default Orders;
