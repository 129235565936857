import './App.scss';
import { useContext, useEffect, useState } from 'react';
import { isSessionAuthenticated } from './actions/auth';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import LoginPage from '../src/view/login/login';
import MainPage from '../src/view/main/main';
import { sessionStore } from './store/session';
import { Loading } from './view/components/loading';

function App() {
  const [isLoading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const { dispatch, state } = useContext(sessionStore);
  const { sessionReady } = state.toJS();

  useEffect(() => {
    isSessionAuthenticated(dispatch).then(authenticated => {
      setAuthenticated(authenticated);
      setLoading(false);
    });
  }, [dispatch]);

  if (isLoading || (isAuthenticated && !sessionReady)) return (<Loading />);

  return (
    <Router>
      <Switch>
        {!isAuthenticated && <Route exact path="/login" component={() => <LoginPage setAuthenticated={setAuthenticated} />} />}
        {!isAuthenticated && <Redirect to="/login" />}
        <Route path="/" component={() => <MainPage />} />
      </Switch>
    </Router>
  );
}

export default App;
