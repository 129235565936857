import { useEffect, useState, useContext, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Table, Modal } from 'antd';
import { sessionStore } from "../../../store/session";
import OrderDetailsBody from './orderDetails';
import { STATUS, getOrders, updateOrderStatus, getSearchOrders } from '../actions';
import { notifiableAPICall, notifyError } from '../../components/notification';
import { toShortDateTimeString, toTime12aString } from '../../../utils/datetime';
import { ACTIONS, orderStore } from '../../../store/orders';
import { truncateOrderNo } from '../../../utils/commons';

const STATUS_DETAILS = {
  [STATUS.IN_PROGRESS]: { label: "In Progress", class: 'in_progress' },
  [STATUS.WAITING_FOR_PICKUP]: { label: "Waiting", class: 'waiting_for_pick_up' },
  [STATUS.COMPLETED]: { label: "Completed", class: 'completed' }
}

const OrderTable = ({ category, newOrder, searchPhrase, servicePeriod }, ref) => {

  const { state } = useContext(sessionStore);
  const { venueId, venueConfigurations } = state.toJS();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOrdersLoading, setOrdersLoading] = useState(false);
  const [isActionButtonsDisabled, setActionButtonsDisabled] = useState(false);

  const { dispatch } = useContext(orderStore);

  const sorter = (a, b, prop) => !a[prop] && !b[prop] ? 0 :
    !a[prop] && b[prop] ? 1 :
      a[prop].localeCompare(b[prop]);

  const sortOrder = useCallback((orders) => {
    if (category === STATUS.COMPLETED || category === STATUS.ALL) {
      orders.sort((o1, o2) => sorter(o2, o1, "createdAt"));
    } else {
      orders.sort((o1, o2) => sorter(o1, o2, "createdAt"));
    }
    return orders;
  }, [category]);

  const loadOrders = useCallback(async () => {
    try {
      setOrdersLoading(true);
      let ordrs = await getOrders(venueId, category, undefined,
        servicePeriod.from.toISOString(), servicePeriod.to.toISOString());
      setOrders(sortOrder(ordrs.orders));
      dispatch({ type: ACTIONS.ORDERS_LOADED, payload: ordrs.summary });
      setOrdersLoading(false);
    } catch (e) {
      setOrdersLoading(false);
      notifyError("Something went wrong in loading orders. Please retry again.");
    }
  }, [venueId, sortOrder, servicePeriod, dispatch, category]);

  const searchOrders = useCallback(async () => {
    try {
      console.log(category);
      setOrdersLoading(true);
      let orders = await getSearchOrders(venueId, searchPhrase,
        servicePeriod.from.toISOString(), servicePeriod.to.toISOString(), category);
      setOrders(orders);
      setOrdersLoading(false);
    } catch (e) {
      setOrdersLoading(false);
      notifyError("Something went wrong in searching orders. Please retry again.");
    }
  }, [venueId, searchPhrase, servicePeriod, category]);

  useImperativeHandle(ref, () => ({
    reload() {
      if (searchPhrase) searchOrders();
      else loadOrders();
    }
  }), [loadOrders, searchPhrase, searchOrders]);

  useEffect(() => {
    if (searchPhrase) searchOrders();
    else loadOrders();
  }, [loadOrders, searchOrders, searchPhrase]);

  useEffect(() => {
    if (!searchPhrase && !!newOrder)
      loadOrders();
  }, [loadOrders, newOrder, searchPhrase]);

  const _updateStatus = async (orderId, status) => {
    await updateOrderStatus(venueId, orderId, status);
    await loadOrders();
  };

  const updateStatus = async (orderId, status) => {
    setActionButtonsDisabled(true);
    try {
      await notifiableAPICall(async () => await _updateStatus(orderId, status),
        "order-status-update",
        "Please wait",
        "Order status has been changed successfully",
        undefined, true);
    } catch (e) {
      if (e?.response?.data?.error?.errorCode === "ORDER_ALREADY_COMPLETED") {
        notifyError("Customer has completed this order.");
        await loadOrders();
      } else {
        notifyError("Something went wrong in updating the order status. Please retry.");
      }
    }
    setActionButtonsDisabled(false);
    setSelectedOrder(null);
  };

  const orderColumn = {
    title: "Order No",
    render: (_, record) => {
      return (
        <div className="ky_column">
          <div>{truncateOrderNo(record.orderNo, venueConfigurations.orderNoAutoGenerate)}</div>
        </div>
      );
    },
  };

  const dateColumn = {
    title: servicePeriod.isDayChange ? "Date Time" : "Time",
    sorter: (a, b) => sorter(a, b, "createdAt"),
    render: (_, record) => {
      return (
        <div className="ky_column">
          <div>{servicePeriod.isDayChange ? toShortDateTimeString(record.createdAt) : toTime12aString(record.createdAt)}</div>
        </div>
      );
    },
  };

  const obsoletePhoneNumber = (phoneNumber) => {
    const prefix = phoneNumber.substr(0, 5);
    const len = phoneNumber.length;
    const sufix = phoneNumber.substr(len - 4, len);
    return `${prefix}xxx${sufix}`;
  }

  const customerColumn = {
    title: "Customer",
    render: (_, record) => {
      return (
        <div className="ky_column">
          {record.beeperNo !== undefined ?
            <div className="bpr_no">{`Beeper No: ${record.beeperNo}`}</div> :
            (!record.customerFirstName) ? <div className="bpr_no">{obsoletePhoneNumber(record.customerTelephone)}</div> : <div>{record.customerFirstName}</div>}
        </div>
      );
    },
  };

  const groupColumn = {
    title: "Group",
    sorter: (a, b) => sorter(a, b, "groupName"),
    render: (_, record) => {
      return (
        <div className="ky_column">
          <div>{record.groupName}</div>
        </div>
      );
    },
  };

  const tableColumn = {
    title: "Table",
    sorter: (a, b) => sorter(a, b, "tableNo"),
    render: (_, record) => {
      return (
        <div className="ky_column">
          <div>{record.tableNo}</div>
        </div>
      );
    },
  };

  const buzzer = (record) => {
    if (record.buzzCount === undefined || record.status !== STATUS.WAITING_FOR_PICKUP) return null;
    return (<div className="ky_bz_ct">
      {`Buzzed ${record.buzzCount} times`}
    </div>);
  }

  const statusColumn = {
    title: "Status",
    sorter: (category === STATUS.ALL) ? (a, b) => sorter(a, b, "status") : undefined,
    render: (_, record) => {
      const statusDetails = STATUS_DETAILS[record.status];
      return (
        <div className="ky_column ky_status_col">
          <div className={`ky_status_clm ${statusDetails.class}`}>{statusDetails.label}</div>
          {buzzer(record)}
        </div>
      );
    },
  };

  const columns = [orderColumn, dateColumn, customerColumn];

  if (venueConfigurations.groupEnable) {
    columns.push(groupColumn);
  }

  if (venueConfigurations.tableNoEnable) {
    columns.push(tableColumn);
  }

  columns.push(statusColumn);

  const onBuzz = (orderId, buzzCount) => {
    setOrdersLoading(true);
    const order = orders.find((o) => o.orderId === orderId);
    if (order) {
      order.buzzCount = buzzCount;
    }
    setOrdersLoading(false);
  }

  return (
    <div>
      <Table
        scroll={{ y: window.innerHeight - 300 }}
        tableLayout="fixed"
        dataSource={orders || null}
        columns={columns}
        bordered
        pagination={false}
        loading={isOrdersLoading}
        rowKey="orderId"
        rowClassName={({ orderId }, _) => selectedOrder && orderId === selectedOrder.orderId ? 'ky_rw_act' : null}
        onRow={(order) => {
          return {
            onClick: () => {
              setSelectedOrder(order);
            },
          };
        }}
        className="ky_order_tbl"
      />
      <Modal
        className='ky_modal_nv'
        onCancel={() => setSelectedOrder(null)}
        visible={!!selectedOrder}
        footer={null}
      >
        {selectedOrder && <OrderDetailsBody
          order={selectedOrder}
          venueId={venueId}
          updateStatus={updateStatus}
          isActionButtonsDisabled={isActionButtonsDisabled}
          onBuzz={onBuzz}
          shouldOrderNoTruncate={venueConfigurations.orderNoAutoGenerate}
        />}
      </Modal>
    </div>
  );
}

export default forwardRef(OrderTable);