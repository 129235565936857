import { AutoComplete, Button, Col, Divider, Input, Row, Switch } from 'antd';
import React from 'react';

const GroupNoField = ({ groupEnable, groupAutoGenerate, groupAutoGenerateSwitch,
    onChangeGroupAutoGenerate, formStep, steps, existingGroup,
    onChangeExistingGroup, groupName, existingGroupNos, onGroupSelect,
    onGroupChange, onAdd, onSkip, onEdit, isEditing
}) => {


    if (!groupEnable) return null;

    const GroupAutoGenerationBtnView = groupAutoGenerate ?
        <Row className='kyu-rw-swtch'>
            <Col>
                <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Group name auto generate</p>
            </Col>
            <Switch disabled={existingGroup} size='small' checked={groupAutoGenerateSwitch} onChange={onChangeGroupAutoGenerate} />
        </Row>
        :
        null

    const existingGroupNoBtnView = formStep === steps.GROUP_NO ?
        <>
            <Row className='kyu-rw-swtch'>
                <Col>
                    <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Add to existing group </p>
                </Col>
                <Switch disabled={groupAutoGenerateSwitch} size='small' checked={existingGroup} onChange={onChangeExistingGroup} />
            </Row>
            {
                GroupAutoGenerationBtnView
            }
        </>
        :
        null

    const existingGroupNoView = existingGroup ?
        <AutoComplete
            style={{ width: 200 }}
            placeholder='Select a group name'
            value={groupName}
            options={existingGroupNos}
            filterOption={(inputValue, option) => option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            onSelect={onGroupSelect}
            onChange={onGroupChange}
            defaultOpen={true}
        />
        :
        <Input
            placeholder='Enter a group name'
            value={groupName}
            onChange={(e) => onGroupChange(e.target.value)}
        />

    const groupNoView = formStep === steps.GROUP_NO ?
        (
            <Col className='kyu-npt-cntnr '>
                <p className="ky_nwordr_drwr_txt">Group</p>
                <Row className='kyu-rw-npt kymt_2'>
                    <Col className="ky_grp_no kymb_2">
                        {
                            existingGroupNoView
                        }
                        <Button type='primary' className='ky_btn ky_btn_rng kyu-fnt-mdm add-group' onClick={onAdd}>{isEditing ? "Save" : "Add"}</Button>
                        <Button type='primary' className='ky_btn ky_btn_blck kyu-fnt-mdm' onClick={() => onSkip(isEditing)}>{isEditing ? "Cancel" : "Skip"}</Button>
                    </Col>
                </Row>
            </Col>
        )
        :
        (
            <Col className='kyu-npt-dtls'>
                <p className='kyu-fnt-mdm ky_nwordr_drwr_txt'>Group Name</p>
                <p className='kyu-fnt-mdm'>{groupName}</p>
                {formStep > steps.GROUP_NO ? <div><Button className='ky_link kyu-fnt-mdm' type='link' onClick={onEdit}>Edit</Button></div> : <p></p>}
            </Col>
        )

    return (
        <>
            {
                existingGroupNoBtnView
            }
            <Row className='kyu-rw'>
                {
                    groupNoView
                }
            </Row>
            <Divider />
        </>
    );
}

export default GroupNoField;