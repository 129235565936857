import { Input } from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from '@ant-design/icons';

const { Search } = Input;

const SearchOrders = ({ onSearch }) => {
  const [phrase, setPhrase] = useState(null);

  useEffect(() => {
    let interval;
    function startPushing() {
      let lastPhrase;
      interval = setInterval(() => {
        if (lastPhrase === phrase) return;
        onSearch(phrase);
        lastPhrase = phrase;
      }, 500);
    }

    function stopPushing() {
      if (interval) clearInterval(interval);
      interval = null;
    }

    if (phrase && !interval) {
      startPushing();
    } else if (interval && !phrase) {
      stopPushing();
    } else if (!interval && !phrase) {
      onSearch(null);
    }

    return () => {
      if (interval) clearInterval(interval);
      interval = null;
    }
  }, [phrase, onSearch]);

  const onTextSearch = (value) => setPhrase(value);
  const onChange = (e) => setPhrase(e.target.value);

  return (
    <div className="ky_srch_wrp">
      <Search
        className="ky_srch kymt_4"
        placeholder="Search order number, customer, group name or table number"
        onSearch={onTextSearch}
        enterButton="Search"
        onChange={onChange}
        allowClear
        prefix={<SearchOutlined />}
      />
    </div>
  );
};

export default SearchOrders;
