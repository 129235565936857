import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Radio, Col, Input, Row, Button } from 'antd';
import { LoadingIcon } from './../../components/loading';
import { RADIO } from './constants';
import { getCustomerByPhoneNumber } from '../actions';
import { notifiableAPICall, notifyError } from './../../components/notification';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { DEFAULT_COUNTRY_CODE, AVAILABLE_COUNTRY_CODES } from '../../../config/app-config';
import StatusIcon, { TYPE } from '../../components/statusIcon';

const TeleNoBeeper = forwardRef((Props, ref) => {
    const {
        setCustomer,
        customer,
        setError,
        isError,
        setOrderReady,
        setPhoneNumber,
        phoneNumber,
        setWithNoCustomer,
        withNoCustomer,
        setBeeperValue,
        beeperValue,
        radioValue,
        setRadioValue
    } = Props;

    const [isSearch, setIsSearch] = useState(false);
    const [isSearchBtn, setIsSearchBtn] = useState(true);

    const radioOnChange = (value) => {
        setWithNoCustomer(false);
        setError(false);
        setPhoneNumber();
        setBeeperValue();
        setIsSearch(false);
        setIsSearchBtn(true);
        setOrderReady(false);
        setCustomer();
        setRadioValue(value);
    }

    const onChangePhoneNumber = (value) => {
        setError(false);
        setPhoneNumber(value);
    }

    const clear = () => {
        setCustomer()
        setOrderReady(false);
    }

    const onSearchCustomer = async (value) => {
        clear();
        const error = phoneNumber ? (isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'
        if (error) {
            notifyError(error);
            return;
        }
        setIsSearch(true);
        const result = await notifiableAPICall(async () => getCustomerByPhoneNumber(value),
            "Get Customer",
            "Searching",
            "Search completed",
            "Something went wrong please try again!",
            true
        );
        if (!!result) {
            const name = `${result.firstName} ${result.lastName}`;
            setCustomer({ name: name, id: result.customerId })
            setIsSearch(false);
            setOrderReady(true);
            setIsSearchBtn(false);
        }
        else {
            setWithNoCustomer(true);
            setIsSearch(false);
            setOrderReady(true);
            setIsSearchBtn(false);
        }

    }

    const onChangeBeeper = (value) => {
        setWithNoCustomer(true);
        setBeeperValue(value);
    }

    useImperativeHandle(ref, () => ({
        reset() {
            setWithNoCustomer();
            setPhoneNumber();
            setIsSearchBtn(true);
        }
    }));

    useEffect(() => {
        if (beeperValue) {
            setOrderReady(true);

        } else {
            setOrderReady(false);
        }
    }, [beeperValue, setOrderReady]);

    const inProgress = isSearch ?
        (
            <Row className="kymt_3">
                <div className="kymr_2 ky_srch_ldng">
                    <LoadingIcon />
                </div>
                <div className="ky_srch_cstmr">Searching customer</div>
            </Row>
        )
        :
        null

    const continueView = withNoCustomer ?
        (
            <div className="ky-msg-wrapper">
                <StatusIcon type={TYPE.FAILED} />
                <p className="ky-err-txt">Can't find customer. Do you want to continue?</p>
            </div>
        )
        :
        null

    const customerView = !!customer ?
        (
            <div className="ky-msg-wrapper">
                <StatusIcon type={TYPE.SUCCESS} />
                <p className="ky-success-txt">Customer <strong>{customer.name}</strong> was found.</p>
            </div>
        )
        :
        (

            continueView

        )

    const errorView = isError ?
        (
            <>
                <div className="ky-msg-wrapper">
                    <StatusIcon type={TYPE.FAILED} />
                    <p className='ky-err-txt'>That didn't work, Try again or click for more connection options</p>
                </div>
            </>
        )
        :
        null

    const searchBtnView = isSearchBtn ?
        (
            <Col span={8} className='kymt_2 ky-w-100'>
                <Button className="ky_btn ky_btn_rng kyu-fnt-mdm ky_btn_cstmr_srch ky_btn_lng" onClick={() => onSearchCustomer(phoneNumber)}>Search</Button>
            </Col>
        )
        :
        null

    const processingView = radioValue === RADIO.BTN_ONE ?
        (
            <Col>
                <div className="kymt_2">Phone Number</div>
                <Row>
                    <Col span={16} className="kymt_2">
                        <PhoneInput
                            countries={AVAILABLE_COUNTRY_CODES}
                            disabled={!isSearchBtn}
                            international
                            addInternationalOption={false}
                            countryCallingCodeEditable={false}
                            defaultCountry={DEFAULT_COUNTRY_CODE}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={(value) => onChangePhoneNumber(value)}
                        />
                    </Col>
                    {
                        searchBtnView
                    }
                </Row>
                {
                    inProgress
                }
                {
                    customerView
                }
                {
                    errorView
                }
            </Col>
        )
        :
        (
            <Col className='kymb_4'>
                <div className="kymt_2">Beeper Number</div>
                <div span={16} className="kymt_2">
                    <Input
                        placeholder='Enter beeper number'
                        onChange={(value) => onChangeBeeper(value.target.value)}
                        value={beeperValue}
                    />
                </div>
            </Col>
        )

    return (
        <div>
            <Radio.Group onChange={(value) => radioOnChange(value.target.value)} value={radioValue}>
                <Radio className='kyu-radio-btn' value={RADIO.BTN_ONE}>
                    Phone Number
                </Radio>
                <div className='kyu-radio-btn-dsc'>( Enter customer's phone number to manually call or text )</div>
                <Radio className='kyu-radio-btn' value={RADIO.BTN_TWO}>
                    Hardware Beeper
                </Radio>
                <div className='kyu-radio-btn-dsc'>( Provide a hardware beeper )</div>
            </Radio.Group>
            {
                processingView
            }
        </div>
    );
});

export default TeleNoBeeper;
