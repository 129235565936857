export const INPUT_TYPE = {
    OrderNo: "Order number",
    TableNo: "Table number",
    GroupNo: "Group number"
};

export const DRAWER_WIDTH = {
    desktop: "50%",
    tablet: "75%",
    mobile: "100%"
}

export const STEPS = {
    OC: {
        ORDER_NO: 1,
        TABLE_NO: 0,
        GROUP_NO: 0,
        CONNECT_CUSTOMER: 2
    },
    OTC: {
        ORDER_NO: 1,
        TABLE_NO: 2,
        GROUP_NO: 0,
        CONNECT_CUSTOMER: 3
    },
    OGC: {
        ORDER_NO: 1,
        TABLE_NO: 0,
        GROUP_NO: 2,
        CONNECT_CUSTOMER: 3
    },
    OTGC: {
        ORDER_NO: 1,
        TABLE_NO: 2,
        GROUP_NO: 3,
        CONNECT_CUSTOMER: 4
    }
}

export const OPTION_LEVELS = {
    QR_SCANNER: 1,
    NFC_CUS_ID: 2,
    Tel_NO_BEEPER: 3
}

export const RADIO = {
    BTN_ONE: 1,
    BTN_TWO: 2
}

export const MODAL_WIDTH = {
    DESKTOP: '40%',
    OTHER: '80%'
}