import React, { createContext, useReducer } from 'react';

const ACTIONS = {
    ORDERS_LOADED: "orders_loaded"
}

const initialState = {
    orders : {}
};

const orderStore = createContext(initialState);
const { Provider } = orderStore;

const OrderStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        const { payload, type } = action;
        switch (type) {
            case ACTIONS.ORDERS_LOADED:
                return {...state, orders : payload};
            default:
                throw new Error("action not defined.");
        };
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

export { orderStore, OrderStateProvider, ACTIONS }